import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./CommonTable.css";
import { TablePagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowDownUp } from "lucide-react";

const CommonTable = (props) => {
  const { headings, tableData, page, total, onPageChange, noPaginate, inactiveWholeRow, setSortedData, sortKey } = props;

  const navigate = useNavigate();

  return (
    <div className="table_wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {headings?.map((heading, i) => {
                let toSort = sortKey?.find((item)=> +item.index === +i)
                return (
                  <TableCell key={i} align={`${i === 0 ? "left" : "center"}`} onClick={()=>{if(toSort){setSortedData(toSort?.key)}} }>
                    {toSort && 
                    <span className="sortColum" >
                      <ArrowDownUp />
                    </span>
                   }
                    {heading}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((rowData, i) => {
              const id = rowData[0];
              return (
                <TableRow
                  key={i}
                  onClick={() =>
                    navigate(
                      `${
                        window.location.pathname === "/"
                          ? "/purchase"
                          : window.location.pathname
                      }/${id}`
                    )
                  }
                  style={{ cursor: "pointer" }}
                  className={`${(inactiveWholeRow?.length > 0 && inactiveWholeRow.find((item)=> item.id == id)?.status != "active") ? "inactive_row" : ""}`}
                >
                  {rowData?.slice(1)?.map(
                    (
                      row,
                      i // slice to remove first element (ID)
                    ) => (
                      <TableCell
                        key={i}
                        align={`${i === 0 ? "left" : "center"}`}
                        // onClick={() => {
                        //   navigate(`${id}`);
                        // }}
                      >
                        {row}
                      </TableCell>
                    )
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!noPaginate && (
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={50}
          page={page}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};
export default CommonTable;
