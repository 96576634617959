import React, { useEffect, useState } from "react";
import css from "../PurchaseOrder/PurchaseOrder.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { createStockAdjustmentApi, fetchStockAdjustmentEdit } from "../../ApiHandler/StockAdjustment.duck";
import StockAdjustmentSchema from "./StockAdjustmentSchema";
import { searchItemsApi } from "../../ApiHandler/PurchaseOrder.duck";
import { searchProductsOnlyApi } from "../../ApiHandler/Products.duck";
import moment from "moment";

const StockAdjustmentNew = () => {
  const { Id } = useParams();
  const navigate = useNavigate()
  const [ editData, setEditData ] = useState({});
  const [itemQuery, setItemQuery] = useState();
  const [selectedItem, setSelectedItem] = useState({id: '', label: ''});
  const [allItems, setAllItems] = useState([]);

  const searchItems = async () => {
    const response = await searchProductsOnlyApi(itemQuery);
    if (response?.data?.products?.length > 0) {
      setAllItems([...response?.data?.products]);
    }
  };

  useEffect(() => {
    if (itemQuery) {
      searchItems();
    }
  }, [itemQuery]);

  const typesOptions = [
    {label: "Damaged goods", value: 'damaged_goods'},
    {label: "Stolen goods", value: 'stolen_goods'},
    {label: "Stock written off", value: 'stock_written_off'},
    {label: "Inventory reevaluation", value: 'inventory_reevaluation'},
    {label: "Restock", value: 'restock'},
    {label: "Stocktaking results", value: 'stocktaking_results'}
  ];

  const initValues = {
    name: "",
    email: "",
    phone: "",
    payment_terms: "upon_receipt",
    stock_adjustment_type: typesOptions[0]?.value,
    adjustment_date: moment(new Date()).format("YYYY-MM-DD"),
    available_qty: 0
  };

  
  const getEdit = async ()=>{
    if(Id){
      const response = await fetchStockAdjustmentEdit(Id);
      setEditData({});
      setEditData(response?.data?.edit_data);
      setAllItems(response.data?.edit_data?.product_options || [])
      setSelectedItem(response.data?.edit_data?.product_options?.[0])
    }
  }

  useEffect(()=>{
    getEdit();
  },[Id])
  

  const createStockAdjustment = ()=>{
    let formData =  JSON.parse(JSON.stringify(formik.values));
    createStockAdjustmentApi(formData).then((response)=> {
      if(response.success){
        toast("StockAdjustment created successfully");
        navigate('/adjustments')
      }else{
        console.log("response.error",response.error);
        toast(response.error)
      }
    })
  }

  const formik = useFormik({
    initialValues: editData?.id ? editData : initValues,
    onSubmit: createStockAdjustment,
    enableReinitialize: true,
    validationSchema: StockAdjustmentSchema
  });

  const handleCancel = ()=>{
    if(Id){
      navigate(`/adjustments/${Id}`)
    }else{
      navigate(`/adjustments`)
    }
  }
  return (
    <div className={css.commonBox}>
      <form onSubmit={formik.handleSubmit}>
        <h2 className={css.pageHeader}>Adjust Stock {selectedItem?.id ? `- ${selectedItem?.label}` : ""}</h2>
        <div className={css.formWrapper}>
        <div className={css.formRow}>
          <label htmlFor="name">Date</label>
          <TextField
            type={"date"}
            id="adjustment_date"
            name="adjustment_date"
            sx={{ width: 356, height: 38 }}
            value={formik.values?.adjustment_date}
            onChange={formik.handleChange}
            size="small"
            error={formik?.errors?.adjustment_date}
            helperText={formik?.errors?.adjustment_date}
          />
        </div>
        <div className={css.formRow}>
          <label htmlFor="product">Product</label>
          <br/>
          <Autocomplete
            disablePortal
            id="product"
            options={allItems?.map((p) => {
              return { id: p.id, label: p.label };
            })}
            noOptionsText={
              <div className={css.notItemAvailable}>
                <span>No Item available</span>
              </div>
            }
            clearOnBlur={false}
            sx={{ width: 356, padding: 0 }}
            size="small"  
            onChange={(e, val) => {
              setSelectedItem({id: val?.id || '', label: val?.label || ''});
              let currentProduct = allItems?.find((p)=> +p.id === +val?.id)
              formik?.setFieldValue('available_qty',currentProduct?.available_stock )
              formik?.setFieldValue('product_id', `${val?.id}`)
            }}
            onInputChange={(event, val) => {
              if(!val){
                setAllItems([...[]])
              }
              setItemQuery(val);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Type or click to select an item"
                variant="outlined"
                value={itemQuery}
                error={formik.errors.product_id}
                helperText={formik.errors.product_id}
              />
            )}
            value={selectedItem}
          />
        </div>  
        <div className={css.formRow}>
          <label htmlFor="name">Current available quanity</label>
          <TextField
            type={"number"}
            id="available_qty"
            name="available_qty"
            disabled
            sx={{ width: 356, height: 38 }}
            value={formik.values?.available_qty}
            onChange={formik.handleChange}
            size="small"
            error={formik?.errors?.available_qty}
            helperText={formik?.errors?.available_qty}
          />
        </div>
        <div className={css.formRow}>
          <label htmlFor="name">New quantity on hand</label>
          <TextField
            type={"number"}
            id="qty_onhand"
            name="qty_onhand"
            sx={{ width: 356, height: 38 }}
            value={formik.values?.qty_onhand}
            onChange={formik.handleChange}
            size="small"
            error={formik?.errors?.qty_onhand}
            helperText={formik?.errors?.qty_onhand}
          />
        </div>
        <div className={`${css.formRow} ${css.mb28}`}>
            <label id="stock_adjustment_type_label" htmlFor="stock_adjustment_type_id">
              Reason
            </label>
            <Select
              labelId="stock_adjustment_type_label"
              id="stock_adjustment_type_id"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.reason}
              key={formik.values?.reason}
              onChange={(e) =>
                formik.setFieldValue("reason", e?.target?.value)
              }
              error={formik?.errors?.reason}
              helperText={formik?.errors?.reason}
            >
              {typesOptions?.map((cat) => {
                return (
                  <MenuItem key={cat.value} value={cat.value}>
                    {cat.label}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className={css.formRow}>
            <label htmlFor="name">Description</label>
            <br/>
            <TextField
              type={"text"}
              id="description"
              name="description"
              multiline
              minRows={3}
              sx={{ width: 356 }}
              value={formik.values?.description}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.description}
              helperText={formik?.errors?.description}
            />
          </div> 
                 
        </div>
        <div className={css.footerActionButtons}>
          <button className={`${css.primaryButton} btnFill btn`} type="submit">
            Save
          </button>
          <button type="button" className={`${css.simpleButton} btnOutline btn`} onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default StockAdjustmentNew;
