import * as Yup from "yup";

const TariffSchema = Yup.object().shape({
  code: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter code"),
  ict_rate: Yup.string().required("Please enter ICT rate"),
  icd_rate: Yup.string()
    .required("Please enter ICD rate"),
  
});

export default TariffSchema;
