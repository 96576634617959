import React, { useEffect, useState } from "react";
import CommonTable from "../../components/CommonTable";
import css from "../PurchaseOrder/PurchaseOrder.module.css";
import ButtonGroup from "../../components/Layout/ButtonGroup";
import Button from "../../components/Layout/Button";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { fetchVendorsApi } from "../../ApiHandler/Vendor.duck";
import { sortArray } from "../../components/Helper";

const Products = () => {
  const allStates = ["all", "active", "inactive"];
  const [currentState, setCurrentState] = useState(allStates[0]);
  const [allProducts, setProducts] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [sortAsc, setSortAsc] = useState(false);
  const navigate = useNavigate();
  const { Id } = useParams();
  const headings = ["Vendor Name", "Email", "Phone", "Status "];
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const fetchVendors = async () => {
    const response = await fetchVendorsApi(currentState, page + 1);
    let tableData = response?.data?.vendors?.map((o) => {
      let status =
        o.status === "active" ? (
          <span className="active">Active</span>
        ) : (
          <span className="inactive">Inactive</span>
        );
      return [o.id, o.name, o.email, o.phone, status];
    });
    setAllVendors(response?.data?.vendors || []);
    setProducts([]);
    setProducts(tableData);
    setTotal(response?.data?.total);
  };

  useEffect(() => {
    fetchVendors();
  }, [currentState, Id]);

  if (Id) {
    return <Outlet />;
  }

  const setSortedData = (key) => {
    let tableData = sortArray(allVendors, key, `${sortAsc ? "asc" : ""}`);
    tableData = tableData?.map((o) => {
      let status =
        o.status === "active" ? (
          <span className="active">Active</span>
        ) : (
          <span className="inactive">Inactive</span>
        );
      return [o.id, o.name, o.email, o.phone, status];
    });
    setProducts(tableData);
    setSortAsc(!sortAsc);
  };
  return (
    <div className={css.commonBox}>
      <div
        className={`${css.flex} justify_between align_center pageHeaderWrapper`}
      >
        <h2 className={`${css.pageHeader} ${css.m0}`}>Vendors</h2>
        <Button
          type="button"
          className={css.commonButton || "btnFill btn"}
          onClick={() => navigate("/vendors-new")}
        >
          New Vendor
        </Button>
      </div>
      <ButtonGroup>
        {allStates?.map((state, ind) => {
          return (
            <Button
              type="button"
              key={ind}
              selected={currentState == state}
              onClick={() => setCurrentState(state)}
            >
              {state}
            </Button>
          );
        })}
      </ButtonGroup>
      <CommonTable
        {...{ headings, page, total, onPageChange, setSortedData }}
        tableData={allProducts}
        sortKey={[{ index: 0, key: "name" }]}
      />
    </div>
  );
};

export default Products;
