import React from "react";
import "./DeleteConfirm.css";

const DeleteConfirm = (props) => {
    const {closeDeleteModal, handleDelete} = props;
    return(
        <div className="delete_modal_confirmation">
            <div className="backdrop"></div>
            <div className="delete_modal_body">
                <p>Are you sure?</p>
                <div className="buttongroups">
                    <button type="button" className="cancel_button" onClick={closeDeleteModal}>Cancel</button>
                    <button type="button" className="confirm_button" onClick={()=>handleDelete()}>Yes</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteConfirm