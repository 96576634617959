import React, { useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { Autocomplete, MenuItem, Select, TextField } from '@mui/material';
import css from '../PurchaseOrder/PurchaseOrder.module.css';
import { createSalesDeliveryApi, salesDelivery } from '../../ApiHandler/SalesOrder.duck';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import ItemTableRecieve from '../PurchaseOrder/ItemTableRecieve';

const AddNewSalesDelivery = () => {
  const { Id } = useParams();
  const [editData, setEditData] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [allMajorItems, setAllMajorItems] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState({});
  const [vendorQuery, setVendorQuery] = useState('');
  const [addVendor, setAddVendor] = useState(false);

  const navigate = useNavigate();

  const handleBeforeUnload = (event) => {
    event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  const paymentTermOptions = [
    { label: 'Upon Receipt', value: 'upon_receipt' },
    { label: 'Net 15', value: 'net15' },
    { label: 'Net 30', value: 'net30' },
  ];

  const fetchPurchaseReceive = async () => {
    const response = await salesDelivery(Id);
    const salesOrder = response?.data?.sales_delivery;
    if (salesOrder) {
      setAllItems([...salesOrder.items]);
      setEditData({ ...salesOrder });
    }
  };

  useEffect(() => {
    if (Id) {
      fetchPurchaseReceive();
    }
  }, []);

  const createSalesDelivery = async () => {
    const formdata = JSON.parse(JSON.stringify(formik.values));
    const params = { sales_delivery: formdata };
    createSalesDeliveryApi(params).then((response) => {
      if (response.success) {
        toast('Saved successfully');
        resetForm();
        navigate(`/salesDeliveryShow/${Id}`);
      } else {
        toast(response.error);
      }
    });
  };

  const formik = useFormik({
    initialValues: editData?.id ? editData : {
      items: allItems,
      date_delivered: editData?.date_delivered
    },
    onSubmit: createSalesDelivery,
    enableReinitialize: true,
  });

  const handleCancel = () => {
    if (Id) {
      window.location.replace(`/sales-orders/${Id}`);
    } else {
      window.location.replace(`/`);
    }
  };

  const resetForm = () => {
    formik.resetForm();
    setAllVendors([]);
    setVendorQuery('');
  };


  return (
    <div className={`${css.commonBox} addNewPurchaseReceive`}>
      <form onSubmit={formik.handleSubmit}>
        <h2 className={css.pageHeader}>{'New'} Sales Delivery</h2>
        <div className={css.formWrapper}>
          <div className={`${css.formRow} formRow`}>
            Sales Order
            <b>{editData?.formatted_order_number}</b>
          </div>
          <div className={`${css.formRow} formRow`}>
            Customer
            <b>{editData?.customer_name}</b>
          </div>
          <div className={`${css.formRow} formRow`}>
            Date delivered
            <TextField
              type={"date"}
              id="date_delivered"
              name="date_delivered"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.date_delivered}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.date_delivered}
              helperText={formik?.errors?.date_delivered}
            />
          </div>
          <ItemTableRecieve
            defaultItems={allItems}
            type="sales_order"
            {...{
              formik,
              editData,
            }}
          />
        </div>
        <div className={css.footerActionButtons}>
          <button
            className={`${css.primaryButton} btnFill btn`}
            type="button"
            onClick={() => {
              formik.setFieldValue('status', 'received');
              formik.handleSubmit();
            }}
          >
            Save
          </button>
          <button
            type="button"
            className={`${css.simpleButton} btnOutline btn`}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewSalesDelivery;