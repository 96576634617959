import React, { useEffect, useState } from "react";
import {
  fetchTransactiosnApi,
  toogleStatusApi,
} from "../../ApiHandler/Products.duck";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DetailWIthTabs from "../../components/Detail/DetailWIthTabs";

const ProductShow = () => {
  const { Id } = useParams();
  const [productData, setProductData] = useState({});
  const [currentStatus, setCurrentStatus] = useState("active");
  const [tableData, setTableData] = useState([]);
  const [transactionsOf, setTransactionOf] = useState({
    of: "purchase_orders",
    status: "all",
  });
  const [headings, setHeading] = useState([]);
  const navigate = useNavigate();
  const productShow = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/product/${Id}`, {
      method: "GET",
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.product) {
          setProductData(data?.product);
          setCurrentStatus(data?.product?.status);
        }
      });
  };

  const fetchTransactions = async () => {
    const data = await fetchTransactiosnApi(Id, transactionsOf);
    const tbdata =
      data?.data?.transactions?.map((o) => [
        o.id,
        o.order_date,
        o.code,
        o.vendor_name,
        o.quantity,
        <span className="fcapitalize">{o.status}</span>,
        `$${+o.price?.toFixed(2)}`,
        `$${(+o.quantity * +o.price)?.toFixed(2)}`,
      ]) || [];
    setTableData([...tbdata]);
  };
  useEffect(() => {
    productShow();
    fetchTransactions();
    //set headings
    let _headings = [
      "DATE",
      transactionsOf?.of == "purchase_orders" ? "PURCHASE ORDER#" : "SALES ORDER#" ,
      transactionsOf?.of == "purchase_orders" ? "VENDOR NAME" : "CUSTOMER NAME",
      transactionsOf?.of == "purchase_orders" ? "QUANTITY RECEIVED" : "QUANTITY" ,
      "STATUS",
      "PRICE",
      "TOTAL",
    ]
    setHeading(_headings)
  }, [transactionsOf]);


  const getformattedPurchaseData = () => {
    return [
      { key: "Name", value: productData?.name },
      { key: "UPC", value: productData?.ups },
      { key: "Category", value: productData?.category },
      {
        key: "Unit",
        value: productData?.unit,
      },
      { key: "Selling price", value: productData?.selling_price },
      { key: "Purchasing price", value: productData?.purchase_price },
      { key: "Preferred vendor", value: productData?.vendor_name },

      { key: "Stock on hand", value: productData?.stock_on_hand },
      { key: "Reordering level", value: productData?.reordering_level },
      { key: "Committed stock", value: productData?.committed_stock },
      { key: "Opening stock", value: productData?.opening_stock || 0 },
      { key: "Available for sale", value: productData?.available_for_sale },
    ];
  };

  const toogleStatus = async () => {
    const response = await toogleStatusApi(Id);
    if (response?.data?.success) {
      productShow();
      toast("Changed status Successfully");
    }
  };

  const handleDelete = async () => {
    let response = {};
    if (response?.data?.success) {
      toast("Deleted Successfully");
      navigate("/category");
    } else {
      toast(response?.data?.error);
    }
  };

  return (
    <>
      <DetailWIthTabs
        showData={getformattedPurchaseData()}
        salesData={productData}
        headings={headings}
        tableData={tableData}
        backUrl="/products"
        setTransactionOf={setTransactionOf}
        transactionsOf={transactionsOf}
        toogleStatus={toogleStatus}
        handleDelete={handleDelete}
        editUrl={`/productsNew/${Id}`}
        currentStatus={currentStatus}
        setCurrentStatus={setCurrentStatus}
      />
    </>
  );
};

export default ProductShow;
