import React, { useEffect, useState } from "react";
import css from "../PurchaseOrder/PurchaseOrder.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { toast } from "react-toastify";
import UserSchema from "./UserSchema";
import { createUserApi, fetchUserEdit } from "../../ApiHandler/User.duck";

const UserNew = () => {
  const { Id } = useParams();
  const navigate = useNavigate()
  const [ editData, setEditData ] = useState({});

  const typesOptions = [
    {label: "Business", value: "business"},
    {label: "Individual", value: "individual"}
  ];

  const paymentTermOptions = [
    { label: "Upon Receipt", value: "upon_receipt" },
    { label: "Net 15", value: "net15" },
    { label: "Net 30", value: "net30" },
  ];

  const initValues = {
    name: "",
    email: "",
    phone: "",
    password: ""
  };

  const createUser = ()=>{
    let formData =  JSON.parse(JSON.stringify(formik.values));
    createUserApi({user: formData}).then((response)=> {
      if(response.success){
        toast("User created successfully");
        navigate('/users')
      }else{
        console.log("response.error",response.error);
        toast(response.error)
      }
    })
  }

  const formik = useFormik({
    initialValues: editData?.id ? editData : initValues,
    onSubmit: createUser,
    enableReinitialize: true,
    validationSchema: UserSchema
  });

  const handleCancel = ()=>{
    if(Id){
      navigate(`/users/${Id}`)
    }else{
      navigate(`/users`)
    }
  }

  const getEdit = async ()=>{
    if(Id){
      const response = await fetchUserEdit(Id);
      setEditData({});
      setEditData(response?.data?.edit_data);
    }
  }

  useEffect(()=>{
    getEdit();
  },[Id])
  return (
    <div className={css.commonBox}>
      <form onSubmit={formik.handleSubmit}>
        <h2 className={css.pageHeader}>{Id ? "Edit" : "New"} User</h2>
        <div className={css.formWrapper}>
          <div className={css.formRow}>
            <label htmlFor="name">First Name</label>
            <TextField
              type={"text"}
              id="first_name"
              name="first_name"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.first_name}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.first_name}
              helperText={formik?.errors?.first_name}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="name">Last Name</label>
            <TextField
              type={"text"}
              id="last_name"
              name="last_name"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.last_name}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.last_name}
              helperText={formik?.errors?.last_name}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="name">Email</label>
            <TextField
              type={"text"}
              id="email"
              name="email"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.email}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.email}
              helperText={formik?.errors?.email}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="name">Phone</label>
            <TextField
              type={"text"}
              id="phone"
              name="phone"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.phone}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.phone}
              helperText={formik?.errors?.phone}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="name">Password</label>
            <TextField
              type={"text"}
              id="password"
              name="password"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.password}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.password}
              helperText={Id ? "Please enter password only if you want to change it." : "Please enter password"}

            />
          </div>          
        </div>
        <div className={css.footerActionButtons}>
          <button
                 className={`${css.primaryButton} btnFill btn`}
                type="submit"
          >
            Save
          </button>
          <button
            type="button"
           className={`${css.simpleButton} btnOutline btn`}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserNew;
