import React from "react";
import { Menu, Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import css from "./Layout.module.css";
import Logo from "../../assets/images/Logo.png";

const Header = ({ open, setOpen }) => {
  return (
    <div className={css?.headerContainer}>
      <div className={css?.headerContent}>
        <img src={Logo} />
      </div>
      <span className={css.toggelButton}>
        {open ? (
          <IconButton onClick={() => setOpen(!open)}>
            <Menu />
          </IconButton>
        ) : (
          <IconButton onClick={() => setOpen(!open)}>
            <Close />
          </IconButton>
        )}
      </span>
    </div>
  );
};

export default Header;
