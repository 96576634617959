import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 0,
  },
  billTo: {
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  //
  table: {
    fontSize: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 35,
    textAlign: "left",
  },
  cell: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "stretch",
  },
  header: {
    backgroundColor: "#eee",
  },
  headerText: {
    fontSize: "11",
    fontWeight: "1200",
    color: "#1a245c",
    margin: "8",
  },
  tableText: {
    margin: 10,
    fontSize: 10,
    color: "neutralDark",
  },
  label: {
    textAlign: "right",
    flexBasis: "50%",
  },
});

const BillTo = ({ invoice, pdfHeading }) => (
  <View style={styles.table}>
    <View style={[styles.row, { marginBottom: "30px" }]}>
      <View style={[styles.headerContainer, styles.cell]}>
        <Text style={styles.billTo}>Victors Montserrat Ltd</Text>
        <Text>St. Johns MSR1210</Text>
        <Text>Montserrat</Text>
      </View>
    </View>
    {!pdfHeading?.includes("Sales") && (
      <View style={[styles.row, { marginBottom: "30px" }]}>
        <View style={[styles.headerContainer, styles.cell]}>
          <Text>Vendor Address</Text>
          <Text style={styles.billTo}>Aravins</Text>
          <Text>St Johns</Text>
        </View>
      </View>
    )}

    <View style={styles.row}>
      <View style={[styles.headerContainer, styles.cell]}>
        <Text style={styles.billTo}>{!pdfHeading?.includes("Sales") ? "Deliver To" : "Bill to"}</Text>
        <Text>Ashok's supermarket</Text>
        <Text>Payment terms: {invoice?.payment_terms}</Text>
      </View>
      <View style={[styles.headerContainer, styles.cell]}>
        <View style={styles.row}>
          <Text style={styles.label}>Date:</Text>
          <Text style={styles.info}>{invoice.order_date}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>{pdfHeading?.includes("Sales") ? "Expected Shipment Date:" : "Delivery Date:"}</Text>
          <Text style={styles.info}>{invoice.delivery_date}</Text>
        </View>
      </View>
    </View>
  </View>
);

export default BillTo;
