import * as Yup from "yup";
import moment from "moment";

const SalesOrderSchema = Yup.object().shape({
  expected_shipment_date: Yup.date()
    .transform((value, originalValue) => {
      if (Yup.date()?.isType(value)) {
        return value;
      }
      const result = moment(originalValue, "DD.MM.YYYY", true);
      return result.isValid() ? result.toDate() : new Date();
    })
    .typeError("Please enter a valid date")
    .required("Please enter expected shippment date")
    .min(moment().subtract(1, "days"), "Date is too early"),
  reference: Yup.string(),
  order_date: Yup.date()
    .transform((value, originalValue) => {
      if (Yup.date().isType(value)) {
        return value;
      }
      const result = moment(originalValue, "DD.MM.YYYY", true);
      return result.isValid() ? result.toDate() : new Date();
    })
    .typeError("Please enter a valid date")
    .required("Please enter order date."),
  customer_id: Yup.string().required("Please select a vendor first!"),
  payment_terms: Yup.string().required("Please select a payment terms"),
  items: Yup.array().test(
    "at-least-one-item",
    "At least one item must be selected",
    (items) => {
      console.log("itemsitems", items);
      return items.find((item) => item.vendor_product_id)?.vendor_product_id;
    }
  ),
});

export default SalesOrderSchema;
