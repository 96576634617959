import React, { useEffect, useState } from "react";
import css from "../PurchaseOrder/PurchaseOrder.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import {
  createProductApi,
  fetchCatgoriesApi,
  fetchVendorsApi,
} from "../../ApiHandler/PurchaseOrder.duck";
import ProductSchema from "./ProductSchema";
import { toast } from "react-toastify";
import { fetchProductEdit } from "../../ApiHandler/Products.duck";
import { fetchTariffsApi, searchTariffsApi } from "../../ApiHandler/Tariffs.duck";

const ProductNew = () => {
  const { Id } = useParams();
  const navigate = useNavigate()
  const [ editData, setEditData ] = useState({});
  const [categories, setCategories] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [allTariffs, setAllTariff] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState({ label: "", id: "" });
  const [selectedTariff, setSelectedTariff] = useState({ label: "", id: "" });
  const [vendorQuery, setVendorQuery] = useState();
  const [tariffQuery, setTariffQuery] = useState();

  const initValues = {
    name: "",
    product_category_id: 0,
    ups: "",
    unit: "0",
    physical_stock: "",
    reorder_level: "",
    selling_price: "",
    purchase_price: "",
    prefered_vendor: "",
  };
  

  const getCatgories = async () => {
    const data = (await fetchCatgoriesApi()) || [];
    setCategories([...data]);
  };

  const fetchVendors = async () => {
    let response = await fetchVendorsApi(vendorQuery);
    if (response?.data?.vendors?.length > 0) {
      setAllVendors([...response?.data?.vendors]);
    }
  };

  const fetchTariffs = async () => {
    let response = await searchTariffsApi(tariffQuery);
    if (response?.data?.tariffs?.length > 0) {
      setAllTariff([...response?.data?.tariffs]);
    }
  };

  const createProduct = ()=>{
    let formData =  JSON.parse(JSON.stringify(formik.values));
    createProductApi(formData).then((response)=> {
      if(response.success){
        toast("Product created successfully");
        handleCancel();
      }else{
        console.log("response.error",response.error);
        toast(response.error)
      }
    })
  }

  const getEdit = async ()=>{
    if(Id){
      const response = await fetchProductEdit(Id);
      console.log("responseresponse",response);
      const vendorOptions = response?.data?.edit_data?.vendor_options
      const tariffOptions = response?.data?.edit_data?.tariff_options
      setEditData({});
      setEditData(response?.data?.edit_data?.product);
      setAllVendors([...vendorOptions]);
      setAllTariff([...tariffOptions]);
      setSelectedVendor(vendorOptions?.[0])
      setSelectedTariff(tariffOptions?.[0])
    }
  }

  useEffect(() => {
    getCatgories();
    getEdit();
  }, []);

  useEffect(() => {
    if (vendorQuery) {
      fetchVendors();
    }
  }, [vendorQuery]);

  useEffect(()=>{
    if(tariffQuery){
      fetchTariffs();
    }
  },[tariffQuery])

  const formik = useFormik({
    initialValues: editData?.id ? editData : initValues,
    onSubmit: createProduct,
    validationSchema: ProductSchema,
    enableReinitialize: true,
  });

  const handleCancel = ()=>{
    if(Id){
      navigate(`/products/${Id}`)
    }else{
      navigate(`/products`)
    }
  }
  return (
    <div className={css.commonBox}>
      <form onSubmit={formik.handleSubmit}>
        <h2 className={css.pageHeader}>{Id ? "Edit" : "New"} Product</h2>
        <div className={css.formWrapper}>
          <div className={css.formRow}>
            <label htmlFor="name">Name</label>
            <TextField
              type={"text"}
              id="name"
              name="name"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.name}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.name}
              helperText={formik?.errors?.name}
            />
          </div>
          <div className={`${css.formRow} ${css.mb28}`}>
            <label id="category_label" htmlFor="product_category_id">
              Category
            </label>
            <Select
              labelId="category_label"
              id="product_category_id"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.product_category_id}
              onChange={(e) =>
                formik.setFieldValue("product_category_id", e?.target?.value)
              }
              error={formik?.errors?.product_category_id}
              helperText={formik?.errors?.product_category_id}
            >
              <MenuItem disabled value="0">
                Please select
              </MenuItem>
              {categories?.map((cat) => {
                return (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className={`${css.formRow}`}>
            <label htmlFor="name">UPC</label>
            <TextField
              type={"text"}
              id="ups"
              name="ups"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.ups}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.ups}
              helperText={formik?.errors?.ups}
            />
          </div>
          <div className={`${css.formRow}`}>
            <label id="unit_label" htmlFor="unit">
              Unit
            </label>
            <Select
              labelId="unit_label"
              id="unit"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.unit}
              onChange={(e) => formik.setFieldValue("unit", e?.target?.value)}
              error={formik?.errors?.unit}
              helperText={formik?.errors?.unit}
            >
              <MenuItem disabled value="0">
                Please select
              </MenuItem>
              {["box", "lb"]?.map((unit) => {
                return (
                  <MenuItem key={unit} value={unit}>
                    {unit}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className={css.formRow}>
            <label htmlFor="physical_stock">Opening Stock</label>
            <TextField
              type={"number"}
              id="physical_stock"
              name="physical_stock"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.physical_stock}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.physical_stock}
              helperText={formik?.errors?.physical_stock}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="reorder_level">Reordering Level</label>
            <TextField
              type={"number"}
              id="reorder_level"
              name="reorder_level"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.reorder_level}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.reorder_level}
              helperText={formik?.errors?.reorder_level}
            />
          </div>

          <div className={css.formRow}>
            <label htmlFor="selling_price">Selling Price</label>
            <TextField
              type={"number"}
              id="selling_price"
              name="selling_price"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.selling_price}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.selling_price}
              helperText={formik?.errors?.selling_price}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="purchase_price">Purchasing Price</label>
            <TextField
              type={"number"}
              id="purchase_price"
              name="purchase_price"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.purchase_price}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.purchase_price}
              helperText={formik?.errors?.purchase_price}
            />
          </div>

          <div className={css.formRow}>
            <label htmlFor="prefered_vendor">Preferred Vendor</label>
            <Autocomplete
              id="prefered_vendor"
              options={[...allVendors]}
              sx={{ width: 356, height: 38 }}
              size="small"
              onChange={(e, val) => {
                setSelectedVendor(val);
                formik.setFieldValue("prefered_vendor", val?.id);
              }}
              onInputChange={(event, val) => {
                if (!val) {
                  setAllVendors([...[]]);
                }
                setVendorQuery(val);
              }}
              value={selectedVendor}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vendors"
                  variant="outlined"
                  value={vendorQuery}
                  error={formik?.errors?.prefered_vendor}
                  helperText={formik?.errors?.prefered_vendor}
                />
              )}
            />
          </div>

          <div className={css.formRow}>
            <label htmlFor="tariff_id">Tariff</label>
            <Autocomplete
              id="tariff_id"
              options={[...allTariffs]}
              sx={{ width: 356, height: 38 }}
              size="small"
              onChange={(e, val) => {
                setSelectedTariff(val);
                formik.setFieldValue("tariff_id", val?.id);
              }}
              onInputChange={(event, val) => {
                if (!val) {
                  setAllTariff([...[]]);
                }
                setTariffQuery(val);
              }}
              value={selectedTariff}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tariff"
                  variant="outlined"
                  value={tariffQuery}
                  error={formik?.errors?.tariff_id}
                  helperText={formik?.errors?.tariff_id}
                />
              )}
            />
          </div>
        </div>
        <div className={css.footerActionButtons}>
          <button
                className={`${css.primaryButton} btnFill btn`}
                type="submit"
          >
            Save
          </button>
          <button
            type="button"
           className={`${css.simpleButton} btnOutline btn`}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProductNew;
