import React, { useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
} from "@mui/material";
import css from "./DetailStyle.module.css";
import { toast } from "react-toastify";
import { X } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteConfirm from "../DeleteConfirm/DeleteConfirm";
import CommonTable from "../CommonTable";
import { deleteCategory } from "../../ApiHandler/Categories.duck";

const Detail = (props) => {
  const {
    transactionsOf,
    showData,
    salesData,
    backUrl,
    editUrl,
    actionButtons,
    showDelete,
    setShowDelete,
    headings,
    tableData,
    getTotal,
    showTotalItems,
    handleDelete
  } = props;

  const filterOptions = [
    { label: "Purchase order", value: "purchase_orders" },
    { label: "Sales order", value: "sales_orders" },
  ];
  const { Id } = useParams();
  const navigate = useNavigate();
  const tabs = ["Overview", "Transactions", "History"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const closeDetail = () => {
    navigate(backUrl);
  };

  const closeDeleteModal = () => {
    setShowDelete(false);
  };

  return (
    <div className={css.DetailWrapper}>
      {(showDelete && handleDelete) && <DeleteConfirm {...{ closeDeleteModal, handleDelete }} />}
      <div className={css.main}>
        <div className={css.head}>
          <div className={css.header_wrapper}>
            <h1>{salesData?.name}</h1>
            <div className={css.close_detail}>
              <X onClick={closeDetail} />
            </div>
          </div>
        </div>
        {actionButtons?.length > 0 && (
          <div className={css.buttonGroup}>
            {actionButtons?.map((actionButton) => {
              return actionButton;
            })}
          </div>
        )}
        <div
          className={`${css.info_overview_wrapper} ${css.customer_show_container}`}
        >
          <div className={css.info}>
            <div className={css.infoLeft}>
              {showData?.map((data) => {
                return (
                  <div className={css.showDatatop}>
                    {data?.key}
                    <strong>{data?.value}</strong>
                  </div>
                );
              })}
            </div>
            <div className={css.infoRight}></div>
          </div>

          {tableData?.length > 0 && (
            <>
              <div className={css.table}>
                <div className={css.table_wrapper}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          {headings.map((heading, index) => (
                            <TableCell key={index}>{heading}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData?.map((row, index) => (
                          <TableRow key={index}>
                            {row?.map((data) => {
                              return <TableCell>{data}</TableCell>;
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <div className={css.foot}>
                {showTotalItems ? (
                  <>
                    <div></div>
                    <div className={css.totalPrice}>
                      Total items: {getTotal()?.totalQty}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={css.totalItems}>
                      <p>Total items: {getTotal()?.totalQty}</p>
                    </div>
                    <div className={css.totalPrice}>
                      Total: ${getTotal()?.totalAmount}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Detail;
