import React, { useEffect, useState } from "react";
import CommonTable from "../../components/CommonTable";
import css from "../PurchaseOrder/PurchaseOrder.module.css";
import ButtonGroup from "../../components/Layout/ButtonGroup";
import Button from "../../components/Layout/Button";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { fetchUsersApi } from "../../ApiHandler/User.duck";

const Users = () => {
  const allStates = ["all", "active", "inactive"];
  const [currentState, setCurrentState] = useState(allStates[0]);
  const [allUsers, setUsers] = useState([]);
  const navigate = useNavigate();
  const { Id } = useParams();
  const headings = ["User Name", "Email", "Phone", "Status "];
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const fetchUsers = async () => {
    const response = await fetchUsersApi(currentState, page + 1);
    let tableData = response?.data?.users?.map((o) => {
      let status =
        o.status === "active" ? (
          <span className="active">Active</span>
        ) : (
          <span className="inactive">Inactive</span>
        );
      return [o.id, o.name, o.email, o.phone, status];
    });
    setUsers([]);
    setUsers(tableData);
    setTotal(response?.data?.total);
  };

  useEffect(() => {
    fetchUsers();
  }, [currentState, Id]);

  if (Id) {
    return <Outlet />;
  }

  return (
    <div className={css.commonBox}>
      <div
        className={`${css.flex} justify_between align_center pageHeaderWrapper`}
      >
        <h2 className={`${css.pageHeader} ${css.m0}`}>Users</h2>
        <Button
          type="button"
          className={css.commonButton || "btnFill btn"}
          onClick={() => navigate("/users-new")}
        >
          New User
        </Button>
      </div>
      <ButtonGroup>
        {allStates?.map((state, ind) => {
          return (
            <Button
              type="button"
              key={ind}
              selected={currentState == state}
              onClick={() => setCurrentState(state)}
            >
              {state}
            </Button>
          );
        })}
      </ButtonGroup>
      <CommonTable
        {...{ headings, page, total, onPageChange }}
        tableData={allUsers}
      />
    </div>
  );
};

export default Users;
