import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { createProductApi, fetchCatgoriesApi } from "../../ApiHandler/PurchaseOrder.duck";
import css from "./PurchaseOrder.module.css";
import "./AddNewProduct.css";
import { toast } from "react-toastify";

const AddNewProduct = (props) => {
  const { addItem, setAddItem, vendorId, addNewRow ,setAllItems, allItems, itemQuery, setItemQuery, allMajorItems, setAllMajorItems, selectedItem, setSelectedItem} = props;
  const [formData, setFormData] = useState({name: itemQuery, unit: "case", vendor_id: vendorId});
  const [categories, setCategories] = useState([]);

  const getCatgories = async () => {
    const data = (await fetchCatgoriesApi()) || [];
    setFormData({...formData, product_category_id: data?.[0]?.id})
    setCategories([...data]);
  };
  useEffect(() => {
    getCatgories();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = ()=>{
    if(!vendorId){
      toast("Please select vendor first!")
      return;
    }
    createProductApi(formData).then((response)=> {
      if(response.success){
        toast("Product created successfully");
        setFormData({})
        setAddItem(false)
        addNewRow(null, response.vendor_product)
        const items = allItems;
        setAllItems([...allItems, ...[response?.vendor_product]]);
        setAllMajorItems([...allMajorItems, ...[response?.vendor_product]]);
        setSelectedItem({id: response?.vendor_product.id, label: response?.vendor_product.label})
      }else{
        console.log("response.error",response.error);
        toast(response.error)
      }
    })
  }

  return (
    <>
      <Modal
        open={addItem}
        onClose={() => setAddItem(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="addProductForm" sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add an item
          </Typography>
          <div className="addFormContent">
            <div className={`${css.formRow} PurchaseOrderformRow`}>
              <label htmlFor="name">Name</label>
              <input
                type={"text"}
                id="name"
                value={formData?.name}
                onChange={(e) => handelChange(e)}
              />
            </div>
            <div className={`${`${css.formRow} PurchaseOrderformRow`} PurchaseOrderformRow`}>
              <label htmlFor="product_category_id">Product Category</label>
              <select
                id="product_category_id"
                value={formData?.product_category_id}
                onChange={(e) => handelChange(e)}
              >
                {categories?.map((cat) => {
                  return <option value={cat.id}>{cat.name}</option>;
                })}
              </select>
            </div>
            <div className={`${css.formRow} PurchaseOrderformRow`}>
              <label htmlFor="ups">Ups</label>
              <input
                type={"text"}
                id="ups"
                value={formData?.ups}
                onChange={(e) => handelChange(e)}
              />
            </div>
            <div className={`${css.formRow} PurchaseOrderformRow`}>
              <label htmlFor="unit">Unit</label>
              <select
                id="unit"
                value={formData?.unit}
                onChange={(e) => handelChange(e)}
              >
                {["box", "lb"]?.map((unit) => {
                  return <option value={unit}>{unit}</option>;
                })}
              </select>
            </div>
            <div className={`${css.formRow} PurchaseOrderformRow`}>
              <label htmlFor="price">Price</label>
              <input
                type={"number"}
                id="price"
                value={formData?.price}
                onChange={(e) => handelChange(e)}
              />
            </div>
            <div className={`${css.formRow} PurchaseOrderformRow`}>
              <label htmlFor="reorder_level">Reorder level</label>
              <input
                type={"text"}
                id="reorder_level"
                value={formData?.reorder_level}
                onChange={(e) => handelChange(e)}
              />
            </div>
            <div className={`${css.formRow} PurchaseOrderformRow`}>
              <label htmlFor="physical_stock">Stock</label>
              <input
                type={"number"}
                id="physical_stock"
                value={formData?.physical_stock}
                onChange={(e) => handelChange(e)}
              />
            </div>
            <div className={`${css.formRow} PurchaseOrderformRow`}>
              <label htmlFor="code">Code</label>
              <input
                type={"text"}
                id="code"
                value={formData?.code}
                onChange={(e) => handelChange(e)}
              />
            </div>
          </div>
          <div className="ModalFooter">   
            <button  type="button" className={`${css.primaryButton} btnFill btn`} onClick={()=> handleSubmit()}>Save</button>
            <button  type="button" className={`${css.destructiveButton} btn`} onClick={()=> setAddItem(false)}>Cancel</button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddNewProduct;
