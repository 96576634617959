import { Routes, Route, Navigate } from "react-router-dom";
import Vendors from "../Pages/Vendors/Vendors";
import PurchaseOrder from "../Pages/PurchaseOrder/PurchaseOrder";
import PurchaseOrderNew from "../Pages/PurchaseOrder/PurchaseOrderNew";
import SalesOrderNew from "../Pages/SalesOrder/SalesOrderNew";
import SalesOrder from "../Pages/SalesOrder/SalesOrder";
import SalesOrderShow from "../Pages/SalesOrder/SalesOrderShow";
import PurchaseOrderShow from "../Pages/PurchaseOrder/PurchaseOrderShow";
import Products from "../Pages/Products/Products";
import ProductNew from "../Pages/Products/ProductNew";
import ProductShow from "../Pages/Products/ProductShow";
import VendorNew from "../Pages/Vendors/VendorNew";
import Customers from "../Pages/Customers/Customer";
import CustomerNew from "../Pages/Customers/CustomerNew";
import CustomerShow from "../Pages/Customers/CustomerShow";
import VendorShow from "../Pages/Vendors/VendorShow";
import Tariff from "../Pages/Tariff/Tariff";
import TariffNew from "../Pages/Tariff/TariffNew";
import Users from "../Pages/Users/Users";
import UserNew from "../Pages/Users/UserNew";
import UserShow from "../Pages/Users/UserShow";
import TariffShow from "../Pages/Tariff/TariffShow";
import ProfileSetting from "../Pages/UserRole/Profile/ProfileSetting";
import ForgotPassword from "../Pages/SignIn/ForgotPassword";
import StockAdjustment from "../Pages/StockAdjustment/StockAdjustment";
import StockAdjustmentNew from "../Pages/StockAdjustment/StockAdjustmentNew";
import StockAdjustmentShow from "../Pages/StockAdjustment/StockAdjustmentShow";
import Category from "../Pages/Category/Category";
import CategoryShow from "../Pages/Category/CategoryShow";
import CategoryNew from "../Pages/Category/CategoryNew";
import AddNewPurchaseRecieve from "../Pages/PurchaseOrder/AddNewPurchaseRecieve";
import PurchaseReceiveShow from "../Pages/PurchaseOrder/PurchaseReceiveShow";
import AddNewSalesDelivery from "../Pages/SalesOrder/AddNewSalesDelivery";
import SalesDeliveryShow from "../Pages/SalesOrder/SalesDeliveryShow";
// Import your components

const AppRoutes = (props) => {
  const { currentRole } = props;
  return (
    <Routes>
      {currentRole == "admin" && (
        <>
          <Route path="/products" element={<Products />}>
            <Route path=":Id" element={<ProductShow />} />
          </Route>
          <Route path="/productsNew" element={<ProductNew />}>
            <Route path=":Id" element={<ProductNew />} />
          </Route>

          <Route path="/category" element={<Category />}>
            <Route path=":Id" element={<CategoryShow />}></Route>
          </Route>

          <Route path="/categoriesNew" element={<CategoryNew />}>
            <Route path=":Id" element={<CategoryNew />} />
          </Route>

          <Route path="/sales-orders" element={<SalesOrder />}>
            <Route path=":Id" element={<SalesOrderShow />}></Route>
          </Route>
          <Route path="/salesDeliveryShow/:Id" element={<SalesDeliveryShow />}></Route>          
          <Route path="/salesDelivery/:Id" element={<AddNewSalesDelivery />}></Route>  
          <Route path="/sales-orders-new" element={<SalesOrderNew />}>
            <Route path=":Id" element={<SalesOrderNew />}></Route>
          </Route>

          <Route path="/customers" element={<Customers />}>
            <Route path=":Id" element={<CustomerShow />} />
          </Route>

          <Route path="/customers-new" element={<CustomerNew />}>
            <Route path=":Id" element={<CustomerNew />} />
          </Route>

          <Route path="/" element={<Navigate to="/purchase" />} />
          <Route path="/purchaseRecieve/:Id" element={<AddNewPurchaseRecieve />}></Route>  
          <Route path="/purchaseRecieveShow/:Id" element={<PurchaseReceiveShow />}></Route>     
          <Route path="/purchase" element={<PurchaseOrder />}>
            <Route path="/purchase/:Id" element={<PurchaseOrderShow />}></Route>        
          </Route>
          <Route path="/purchase-order-new" element={<PurchaseOrderNew />}>
            <Route path=":Id" element={<PurchaseOrderNew />} />
          </Route>

          <Route path="/vendors" element={<Vendors />}>
            <Route path=":Id" element={<VendorShow />}></Route>
          </Route>
          <Route path="/vendors-new" element={<VendorNew />}>
            <Route path=":Id" element={<VendorNew />}></Route>
          </Route>

          <Route path="/tariffs" element={<Tariff />}>
            <Route path=":Id" element={<TariffShow />}></Route>
          </Route>
          <Route path="/tariffs-new" element={<TariffNew />}>
            <Route path=":Id" element={<TariffNew />}></Route>
          </Route>

          <Route path="/users" element={<Users />}>
            <Route path=":Id" element={<UserShow />} />
          </Route>

          <Route path="/users-new" element={<UserNew />}>
            <Route path=":Id" element={<UserNew />} />
          </Route>

          <Route path="/adjustments" element={<StockAdjustment />}>
            <Route path=":Id" element={<StockAdjustmentShow />}></Route>
          </Route>
          <Route path="/adjustments-new" element={<StockAdjustmentNew />}>
            <Route path=":Id" element={<StockAdjustmentNew />}></Route>
          </Route>
        </>
      )}
      {currentRole == "user" && <Route path="/" element={<ProfileSetting />} />}

      {/* <Route path="*" element={<p>error page</p>} /> */}
    </Routes>
  );
};

export default AppRoutes;
