import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Detail from "../../components/Detail/Detail";
import { toast } from "react-toastify";
import {
  deletePurchasaeOrder,
  markConfirmAPi,
} from "../../ApiHandler/PurchaseOrder.duck";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfComponent from "../../components/Pdf/PdfComponent";
import { Button } from "@mui/material";
import PreviewPdf from "../../components/Pdf/PreviewPdf";
import css from "./purchaseOrderShow.css";

const PurchaseOrderShow = (props) => {
  const { Id } = useParams();
  const [purchaseData, setPurchasaeData] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [togglePdf, setTogglePdf] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pdfTableData, setPdfTableData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const editUrl=`/purchase-order-new/${purchaseData?.id}`;
  const pdfFileName=`purchase-order-${purchaseData?.order_number}.pdf`;
  const pdfHeading="Purchase order";
  const orderNumber=`# PO-${purchaseData?.order_number}`;
  const previewPdfToggle = () => {
    setTogglePdf(!togglePdf);
    setPreviewPdf(!previewPdf);
  };
  const navigate = useNavigate();
  const purchaseOrderShow = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/purchase_order/${Id}`, {
      method: "GET",
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.purchase_order) {
          data.purchase_order.name = data?.purchase_order?.formatted_order_number
          setPurchasaeData(data?.purchase_order);
          const tbdata =
            data?.purchase_order?.ordered_products?.map((p) => [
              p.quantity?.toFixed(2),
              p.title,
              `$${p.price?.toFixed(2)}`,
              `$${p.amount?.toFixed(2)}`,
            ]) || [];
          setTableData([...tbdata]);
          const pdftbdata =
            data?.purchase_order?.ordered_products?.map((p, i) => [
              `${i + 1}`,
              p.title,
              p.quantity,
              `$${p.price?.toFixed(2)}`,
              `$${p.amount?.toFixed(2)}`,
            ]) || [];
          setPdfTableData([...pdftbdata]);
        }
      });
  };
  useEffect(() => {
    purchaseOrderShow();
  }, []);

  const getTotal = () => {
    let totalAmount = 0;
    let totalQty = 0;
    purchaseData?.ordered_products?.map((o) => {
      totalAmount += o.amount;
      totalQty += o.quantity;
    });
    return { totalAmount: totalAmount, totalQty: totalQty };
  };

  const headings = ["QUANTITY", "ITEM DETAILS", "PRICE", "AMOUNT"];
  const getformattedPurchaseData = () => {
    return [
      { key: "Vendor", value: purchaseData?.vendor_name },
      { key: "Status", value: purchaseData?.status },
      { key: "Date", value: purchaseData?.order_date },
      {
        key: "Iussed",
        value: purchaseData?.issued ? "Iussed" : "Not Iussed",
      },
      { key: "Expected Delivery Date", value: purchaseData?.delivery_date },
      { key: "Bill", value: purchaseData?.billed ? "Billed" : "Not billed" },
      { key: "Payment Terms", value: purchaseData?.payment_terms },
    ];
  };

  const markConfirm = async () => {
    const response = await markConfirmAPi(Id);
    if (response?.data?.success) {
      purchaseOrderShow();
      toast("Confirmed Successfully");
    }
  };

  const handleDelete = async () => {
    const response = await deletePurchasaeOrder(Id);
    if (response?.data?.success) {
      toast("Deleted Successfully");
      navigate("/");
    } else {
      toast(response?.data?.error);
    }
  };

  const actionButtons = [
    <Button
      type="button"
      variant="contained"
      onClick={() => {
        if (editUrl) {
          navigate(editUrl);
        }
      }}
    >
      Edit
    </Button>,
    <Button
      type="button"
      variant="contained"
      onClick={() => console.log("Edit clicked")}
    >
      Email
    </Button>,
    <>
    <div className={css.print_pdf_button_wrapper}>
      <Button
        type="button"
        variant="contained"
        onClick={() => setTogglePdf(!togglePdf)}
      >
        Pdf/Print
      </Button>
      {togglePdf && (
        <ul className={css.print_pdf_drop_down}>
          <li
            className={`${css.print_pdf_list} print_pdf_button`}
            onClick={() =>
              setTimeout(() => setTogglePdf(!togglePdf), 500)
            }
          >
            <PDFDownloadLink
              document={
                <PdfComponent
                  invoice={purchaseData}
                  pdfTableData={pdfTableData}
                  pdfHeading={pdfHeading}
                  orderNumber={orderNumber}
                  totalAmount={getTotal()?.totalAmount}
                />
              }
              fileName={pdfFileName || "victor.pdf"}
              className="buttonDowmload"
            >
              {({ loading }) =>
                loading ? (
                  "Pdf"
                ) : (
                  <>
                    <div className="pdf-export">Pdf</div>
                  </>
                )
              }
            </PDFDownloadLink>
          </li>
          <li className="">
            <a onClick={previewPdfToggle}>Preview</a>
          </li>
        </ul>
      )}
    </div>
    {previewPdf && (
      <PreviewPdf
        {...{
          previewPdf,
          setPreviewPdf,
          pdfTableData,
          pdfHeading,
          orderNumber,
        }}
        salesData={purchaseData}
        totalAmount={getTotal()?.totalAmount}
      />
    )}
    </>
    ,
    <>
    {(!purchaseData?.confirmed && !purchaseData?.received) && (
      <Button type="button" variant="contained" onClick={markConfirm}>
        Marked as Confirmed
      </Button>
    )}
    </>,
    <>
    {(!purchaseData?.received) && (
      <Button
        type="button"
        variant="contained"
        onClick={() => {
          if(purchaseData?.received_date){
            navigate(`/purchaseRecieveShow/${Id}`);
          }else{
            navigate(`/purchaseRecieve/${Id}`);
          }
        }}
      >
        Receive
      </Button>
    )}
    </>
    ,
    <Button
      type="button"
      variant="contained"
      onClick={() => setShowDelete(true)}
    >
      Delete
    </Button>
  ]
  return (
    <>
      <Detail
        showData={getformattedPurchaseData()}
        salesData={purchaseData}
        headings={headings}
        tableData={tableData}
        getTotal={getTotal}
        actionButtons={actionButtons}
        {...{ pdfTableData, setPdfTableData }}
        backUrl={`/`}
        markConfirm={markConfirm}
        handleDelete={handleDelete}
        
        {...{showDelete, setShowDelete}}
      />
    </>
  );
};

export default PurchaseOrderShow;
