import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Detail from "../../components/Detail/Detail";
import { deleteVendor, toggleStatus } from "../../ApiHandler/Vendor.duck";
import { Button } from "@mui/material";

const VendorShow = () => {
  const { Id } = useParams();
  const [vendorData, setVendorData] = useState({});
  const [currentStatus, setCurrentStatus] = useState("active");
  const [transactionsOf, setTransactionOf] = useState({
    of: "purchase_orders",
    status: "all",
  });
  const [showDelete, setShowDelete] = useState(false);
  const navigate = useNavigate();
  const customerShow = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/vendor/${Id}`, {
      method: "GET",
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.vendor) {
          setVendorData(data?.vendor);
          setCurrentStatus(data?.vendor?.status);
        }
      });
  };

  useEffect(() => {
    customerShow();
  }, [transactionsOf]);

  const getFormattedVendorData = () => {
    return [
      { key: "Email", value: vendorData?.email || "Not listed" },
      { key: "Name", value: vendorData?.name || "Not listed" },
      {
        key: "Phone",
        value: vendorData?.phone || "Not listed",
      },
      { key: "Status", value: currentStatus },
    ];
  };

  const handleDelete = async () => {
    let response = {};
    if (response?.data?.success) {
      toast("Deleted Successfully");
      navigate("/vendors");
    } else {
      toast(response?.data?.error);
    }
  };

  const handleToggleStatus = async () => {
    const status = currentStatus === "active" ? "inactive" : "active";
    setCurrentStatus(status);
    const response = await toggleStatus(Id, status);
    toast("Updated successfully");
  };

  const actionButtons = [
    <Button
      type="button"
      variant="contained"
      onClick={() => {
        navigate(`/vendors-new/${Id}`);
      }}
    >
      Edit
    </Button>,
    <Button
      type="button"
      variant="contained"
      onClick={() => handleToggleStatus()}
    >
      Mark as {currentStatus === "active" ? "Inactive" : "Active"}
    </Button>,

    <Button
      type="button"
      variant="contained"
      onClick={() => setShowDelete(true)}
    >
      Delete
    </Button>
  ]
  return (
    <>
      <Detail
        showData={getFormattedVendorData()}
        salesData={vendorData}
        headings={[]}
        tableData={[]}
        backUrl="/vendors"
        setTransactionOf={setTransactionOf}
        transactionsOf={transactionsOf}
        handleDelete={handleDelete}
        editUrl={`/vendors-new/${Id}`}
        currentStatus={currentStatus}
        setCurrentStatus={setCurrentStatus}
        deleteCustomer={deleteVendor}
        toggleStatus={toggleStatus}
        actionButtons={actionButtons}
      />
    </>
  );
};

export default VendorShow;
