import React, { useEffect, useState } from "react";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import ItemTable from "./ItemTable";
import css from "./PurchaseOrder.module.css";
import {
  createPurchaseOrderApi,
  fetchVendorsApi,
  purchaseEditOrders,
} from "../../ApiHandler/PurchaseOrder.duck";
import moment from "moment";
import AddNewVendor from "./AddNewVendor";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import PurchaseOrderSchema from "./PurchaseOrderSchema";
import { useNavigate, useParams } from "react-router-dom";

const PurchaseOrderNew = () => {
  const { Id } = useParams();
  const [editData, setEditData] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [allMajorItems, setAllMajorItems] = useState([]);
  const navigate = useNavigate();
  const handleBeforeUnload = (event) => {
    // Standard message for most browsers
    const message = "You have unsaved changes. Are you sure you want to leave?";
    event.returnValue = message; // Chrome requires returnValue to be set
    return message;
  };
  window.addEventListener("beforeunload", handleBeforeUnload);

  const paymentTermOptions = [
    { label: "Upon Receipt", value: "upon_receipt" },
    { label: "Net 15", value: "net15" },
    { label: "Net 30", value: "net30" },
  ];

  const fetchEdit = async () => {
    const response = await purchaseEditOrders(Id);
    const purchase_order = response?.data?.purchase_order;
    console.log("pruchase_orderpruchase_order", purchase_order?.purchase_order);
    if (purchase_order?.purchase_order) {
      setAllItems([...purchase_order?.item_options]);
      setAllMajorItems([...purchase_order?.item_options]);
      setEditData({ ...purchase_order?.purchase_order });
      setAllVendors([...purchase_order?.vendor_options]);
      setSelectedVendor({ ...purchase_order?.vendor_options[0] });
    }
  };

  useEffect(() => {
    if (Id) {
      fetchEdit();
    }
  }, []);

  const createPurchaseOrder = () => {
    let formdata = JSON.parse(JSON.stringify(formik.values));
    console.log("formdata", formdata);
    formdata.items = formdata?.items.filter((itm) => !!itm.vendor_product_id);
    formdata.total = getTotal();
    let params = { purchase_order: formdata };
    createPurchaseOrderApi(params).then((response) => {
      if (response.success) {
        toast("Purchase order successfully");
        resetForm();
        navigate("/");
      } else {
        toast(response.error);
      }
    });
  };

  const itemInitValue = { vendor_product_id: "", quantity: 0 };
  const initValues = {
    items: [itemInitValue],
    payment_terms: paymentTermOptions[0]?.value,
    order_date: moment(new Date()).format("YYYY-MM-DD"),
    expected_delivery_date: moment(new Date()).format("YYYY-MM-DD"),
    status: "issued",
  };
  const formik = useFormik({
    initialValues: editData?.id ? editData : initValues,
    onSubmit: createPurchaseOrder,
    validationSchema: PurchaseOrderSchema,
    enableReinitialize: true,
  });

  const [vendorQuery, setVendorQuery] = useState();
  const [allVendors, setAllVendors] = useState([]);
  const [addVendor, setAddVendor] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState({ label: "", id: "" });

  const fetchVendors = async () => {
    let response = await fetchVendorsApi(vendorQuery);
    if (response?.data?.vendors?.length > 0) {
      setAllVendors([...response?.data?.vendors]);
    }
  };

  const handleCancel = () => {
    if (Id) {
      window.location.replace(`/purchase/${Id}`);
    } else {
      window.location.replace(`/`);
    }
  };

  const resetForm = () => {
    formik.resetForm();
    setAllVendors([]);
    setVendorQuery();
  };

  useEffect(() => {
    if (vendorQuery) {
      fetchVendors();
    }
  }, [vendorQuery]);

  const getTotal = () => {
    let total = 0;
    formik.values?.items?.map((fi) => {
      let currentItem = allMajorItems?.find(
        (item) => +item.id === +fi?.vendor_product_id
      );
      total += currentItem?.price * fi.quantity || 0;
    });
    return total?.toFixed(2);
  };

  return (
    <div className={css.commonBox}>
      <form onSubmit={formik.handleSubmit}>
        <h2 className={css.pageHeader}>{Id ? "Edit" : "New"} Purchase Order</h2>
        <div className={css.formWrapper}>
          <div className={`${css.formRow} ${css.mb28}`}>
            <label htmlFor="vendor">Vendor</label>
            <Autocomplete
              disablePortal
              id="vendor"
              options={[...allVendors]}
              sx={{ width: 356, height: 38 }}
              size="small"
              onChange={(e, val) => {
                setSelectedVendor(val);
                formik.setFieldValue("vendor_id", val?.id);
                setAllItems([]);
              }}
              onInputChange={(event, val) => {
                if (!val) {
                  setAllVendors([...[]]);
                }
                setVendorQuery(val);
              }}
              value={selectedVendor}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vendors"
                  variant="outlined"
                  value={vendorQuery}
                  error={formik?.errors?.vendor_id}
                  helperText={formik?.errors?.vendor_id}
                />
              )}
              noOptionsText={
                <div className={css.notItemAvailable}>
                  <span>No Item available</span>
                  <button
                    type="button"
                    className={css.addItembutton}
                    onClick={() => {
                      setAddVendor(true);
                    }}
                  >
                    + Add new vendor
                  </button>
                </div>
              }
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="order_date">Date</label>
            <TextField
              type={"date"}
              id="order_date"
              name="order_date"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.order_date}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.order_date}
              helperText={formik?.errors?.order_date}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="expected_delivery">Expected Delivery Date</label>
            <TextField
              error={formik?.errors?.expected_delivery_date}
              type={"date"}
              id="expected_delivery_date"
              name="expected_delivery_date"
              sx={{ width: 356 }}
              size="small"
              value={formik.values?.expected_delivery_date}
              onChange={formik.handleChange}
              helperText={formik?.errors?.expected_delivery_date}
              InputProps={{
                inputProps: {
                  min: moment(new Date()).format("YYYY-MM-DD"),
                },
              }}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="payment_terms">Payment Terms</label>
            <Select
              id="payment_terms"
              name="payment_terms"
              value={formik.values?.payment_terms}
              onChange={formik.handleChange}
              sx={{ width: 356 }}
              size="small"
            >
              {paymentTermOptions?.map((option, i) => {
                return (
                  <MenuItem key={`payopt${i}`} value={option?.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <ItemTable
            defaultItems={allItems}
            {...{
              itemInitValue,
              itemInitValue,
              getTotal,
              allMajorItems,
              setAllMajorItems,
              formik,
              editData,
              selectedVendor,
              setAllItems
            }}
          />
        </div>
        <div className={css.footerActionButtons}>
          <button
            className={`${css.simpleButton} btnOutline btn`}
            type="button"
            onClick={() => {
              formik.setFieldValue("status", "draft");
              formik.handleSubmit();
            }}
          >
            Save as Draft
          </button>
          <button
            className={`${css.primaryButton} btnFill btn`}
            type="button"
            onClick={() => {
              formik.setFieldValue("status", "issued");
              formik.handleSubmit();
            }}
          >
            Save
          </button>
          <button
            type="button"
            className={`${css.simpleButton} btnOutline btn`}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default PurchaseOrderNew;
