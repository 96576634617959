import { Box, Modal, Typography } from "@mui/material";
import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import PdfComponent from "./PdfComponent";
import { X } from "lucide-react";
import css from "./PreviewPdf.module.css"

const PreviewPdf = (props)=>{
    const {previewPdf, setPreviewPdf, salesData,pdfTableData, pdfHeading, orderNumber, totalAmount } = props
    return (
        <>
        <Modal
          open={previewPdf}
          onClose={() => setPreviewPdf(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={css.previewPdf} style={{maxWidth: "1030px",minHeight:"80vh",maxHeight:"80vh",height: "100%", margin: "auto"}}>
            <div className={css.previewPdf_header}>
              <h2>Preview</h2>
              <div className={css.action_buttons}>
                <X onClick={()=> setPreviewPdf(false)}/>
              </div>
            </div>
            <PDFViewer width={"100%"} height={"100%"}>
                <PdfComponent
                    invoice={salesData}
                    pdfTableData={pdfTableData}
                    pdfHeading={pdfHeading}
                    orderNumber={orderNumber}
                    totalAmount={totalAmount}
                />
            </PDFViewer>
          </Box>
        </Modal>
      </>
    )
}

export default PreviewPdf