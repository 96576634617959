import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const searchProductsOnlyApi = async (query) => {
  return await axios.get(
    `${BACKEND_URL}/search_products_only?query=${query || ''}`
  );
};
const fetchProductsApi = async (status, page, query) => {
    return await axios.get(
      `${BACKEND_URL}/products?status=${status}&page=${page || 1}&query=${query || ''}`
    );
};

const fetchTransactiosnApi = async (id, transactions) => {
  return await axios.get(
    `${BACKEND_URL}/fetch_transactions?id=${id}&of=${transactions?.of}&status=${transactions?.status}`
  );
};

const toggleStatus = async (id, status) => {
  return await axios.get(
    `${BACKEND_URL}/toggle_status/${id}/${status}`
  );
};

const deleteProduct = async (Id) => {
  try{
    return await axios.delete(`${BACKEND_URL}/products/${Id}`);
  }catch(error){
    return {error: error}
  }
};



const toogleStatusApi = async (status) => {
  return await axios.post(`${BACKEND_URL}/products/toogleStatus?status=${status}`)
}

const fetchProductEdit = async (Id) => {
  return await axios.get(
    `${BACKEND_URL}/product_edit/${Id}`
  );
}

export {fetchProductsApi, toogleStatusApi , fetchTransactiosnApi,searchProductsOnlyApi, deleteProduct, toggleStatus, fetchProductEdit};