import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// const borderColor = "#454545";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#454545",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 30,
    fontStyle: "bold",    
    padding: "8px 10px 4px",
  },

  sno: {
    width: "10%",
  },

  description: {
    width: "60%",
    textAlign: "left",
    marginLeft: 50,
  },
  qty: {
    width: "10%",
    textAlign: "center",
  },
  rate: {
    width: "15%",
    textAlign: "center",
  },
  amount: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableRow = ({ pdfTableData }) => {
  console.log("pdfTableData",pdfTableData)
  const rows = pdfTableData.map((items, index) => (
    <View style={styles.row} key={`itemTable${index+1}`}>
      {items?.map((item , i)=>{
        return(
          <Text style={i==1 ? styles.description : i == 0 ? styles.hash : i == 2 ? styles.qty : styles?.rate} >{item}</Text>
        )
      })}
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
