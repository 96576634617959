import * as Yup from "yup";
import moment from "moment";

const PurchaseOrderSchema = Yup.object().shape({
  expected_delivery_date: Yup.date()
    .transform((value, originalValue) => {
      if (Yup.date()?.isType(value)) {
        return value;
      }
      const result = moment(originalValue, "DD.MM.YYYY", true);
      return result.isValid() ? result.toDate() : new Date();
    })
    .typeError("please enter a valid date")
    .required("Please enter expected delivery date")
    .min(moment().subtract(1, "days"), "Date is too early"),
  order_date: Yup.date()
    .transform((value, originalValue) => {
      if (Yup.date().isType(value)) {
        return value;
      }
      const result = moment(originalValue, "DD.MM.YYYY", true);
      return result.isValid() ? result.toDate() : new Date();
    })
    .typeError("please enter a valid date")
    .required("Please enter order date."),
  vendor_id: Yup.string().required("Please select a vendor first!"),
  payment_terms: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  items: Yup.array().test(
    'at-least-one-item',
    'At least one item must be selected',
    (items) => {
      console.log("itemsitems",items)
      return items.find((item)=> item.vendor_product_id )?.vendor_product_id
    }
  ),
});

export default PurchaseOrderSchema;
