import React from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";

const AuthComponent = (props)=>{
    const {currentRole, setCurrentRole} = props
    return(
        <Routes>
            <Route path="*" element={<SignIn {...{currentRole, setCurrentRole}}/>} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
        </Routes>
    )
}

export default AuthComponent;