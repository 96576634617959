import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const fetchCustomersApi = async (query) => {
  try {
    return await axios.get(`${BACKEND_URL}/searchCustomers?query=${query}`);
  } catch (e) {
    console.log("Something went wrong", e);
  }
};

const searchItemsApi = async (query) => {
  return await axios.get(`${BACKEND_URL}/searchProducts?query=${query || ""}`);
};

const createSalesOrderApi = async (params) => {
  const response = await fetch(`${BACKEND_URL}/sales_orders`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json" },
  });
  return await response.json();
};

const createCustomerApi = async (params) => {
  const response = await fetch(`${BACKEND_URL}/customers`, {
    method: "POST",
    body: JSON.stringify({ customer: params }),
    headers: { "Content-Type": "application/json" },
  });
  return await response.json();
};

const salesOrders = async (query, page) => {
  return await axios.get(
    `${BACKEND_URL}/sales_orders?status=${query || ""}&page=${page}`
  );
};

const salesEditOrders = async (id) => {
  return await axios.get(
    `${BACKEND_URL}/edit_sales_orders/${id}`
  );
};

const markConfirmAPi = async (Id) => {
  return await axios.get(`${BACKEND_URL}/sales_order/${Id}/mark_confirm`);
};

const deleteSalesOrder = async (Id) => {
  try{
    return await axios.delete(`${BACKEND_URL}/sales_orders/${Id}`);
  }catch(error){
    return {error: error}
  }
};

const createSalesDeliveryApi = async (params) => {
  const response =  await fetch(`${BACKEND_URL}/sales_delivery`,
    {
      method: 'POST',
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
    })
  return await response.json();
}

const salesDelivery = async (id) => {
  return await axios.get(
    `${BACKEND_URL}/get_sales_delivery/${id}`
  );
};

export {
  fetchCustomersApi,
  searchItemsApi,
  createSalesOrderApi,
  createCustomerApi,
  salesOrders,
  deleteSalesOrder,
  markConfirmAPi,
  salesEditOrders,
  createSalesDeliveryApi, salesDelivery
};
