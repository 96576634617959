import React from "react";
import { Page, Document, Image, StyleSheet, Font } from "@react-pdf/renderer";
import InvoiceTitle from "./components/InvoiceTitle";
import BillTo from "./components/BillTo";
import InvoiceItemsTable from "./components/InvoiceItemsTable";
import InvoiceInfo from "./components/InvoiceNo";
import RobotoBold from "./Roboto-Bold.ttf";

Font.register({
  family: "Roboto",
  fonts: [{ src: RobotoBold, fontWeight: "Bold" }],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 50,
    height: 50,
    color: "black",
    borderRadius: 50,
    marginLeft: "0",
    marginRight: "auto",
  },
});

Font.register(
  "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap",
  { fontFamily: "Roboto-Bold" }
);
const PdfComponent = ({ invoice, pdfTableData, pdfHeading, orderNumber, totalAmount }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <InvoiceInfo invoice={invoice} pdfHeading={pdfHeading} orderNumber={orderNumber}/>
      <BillTo invoice={invoice} pdfHeading={pdfHeading}/>
      <InvoiceItemsTable invoice={invoice} pdfTableData={pdfTableData} totalAmount={totalAmount} />
    </Page>
  </Document>
);

export default PdfComponent;
