import React, { useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
} from "@mui/material";
import css from "./DetailWIthTabs.module.css";
import { toast } from "react-toastify";
import { X } from "lucide-react";
import { useNavigate,useParams } from "react-router-dom";
import DeleteConfirm from "../DeleteConfirm/DeleteConfirm";
import CommonTable from "../CommonTable";
import { deleteProduct, toggleStatus } from "../../ApiHandler/Products.duck";

const DetailWIthTabs = (props) => {
  const {
    transactionsOf,
    showData,
    salesData,
    backUrl,
    editUrl,
    headings,
    tableData,
    setTransactionOf,
    currentStatus, setCurrentStatus
  } = props;
  const [showDelete, setShowDelete] = useState(false);
  const filterOptions = [
    { label: "Purchase order", value: "purchase_orders" },
    { label: "Sales order", value: "sales_orders" },
  ];
  const {Id} = useParams();
  const statusOptions = {
    purchase_orders: [
      { label: "All", value: "all" },
      { label: "Draft", value: "draft" },
      { label: "Pending_approval", value: "pending approval" },
      { label: "Approved", value: "approved" },
      { label: "Received", value: "received" },
      { label: "Billed", value: "billed" },
      { label: "Closed", value: "closed" },
      { label: "Cancelled", value: "cancelled" },
    ],
    sales_orders: [
      { label: "All", value: "all" },
      { label: "Draft", value: "draft" },
      { label: "Confirmed", value: "confirmed" },
      { label: "Shipped", value: "shipped" },
      { label: "Invoiced", value: "invoiced" },
      { label: "Closed", value: "closed" },
      { label: "Cancelled", value: "cancelled" },
    ],
  };
  const navigate = useNavigate();
  const tabs = ["Overview", "Transactions", "History"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const closeDetail = () => {
    navigate(backUrl);
  };

  const closeDeleteModal = () => {
    setShowDelete(false);
  };

  const handleDelete = async () => {
    const response = await deleteProduct(Id)
    toast("Product successfully delete...");
    navigate('/products')
  }

  const handleToggleStatus = async ()=>{
    const status = currentStatus === "active" ? "inactive" : "active";
    setCurrentStatus(status)
    const response = await toggleStatus(Id, status)
    toast('Updated successfully');
  }

  return (
    <div className={css.DetailWrapper}>
      {showDelete && <DeleteConfirm {...{ closeDeleteModal, handleDelete }} />}
      <div className={css.main}>
        <div className={css.head}>
          <div className={css.header_wrapper}>
            <h1>{salesData?.name}</h1>
            <div className={css.close_detail}>
              <X onClick={closeDetail} />
            </div>
          </div>
        </div>
        <div className={css.buttonGroup}>
          <Button
            type="button"
            variant="contained"
            onClick={() => {
              if (editUrl) {
                navigate(editUrl);
              }
            }}
          >
            Edit
          </Button>
          <Button
            type="button"
            variant="contained"
            onClick={() => handleToggleStatus()}
          >
            Mark as {currentStatus === "active" ? "Inactive" : "Active"}
          </Button>

          <Button
            type="button"
            variant="contained"
            onClick={() => setShowDelete(true)}
          >
            Delete
          </Button>
        </div>
        <div className={css.info_overview_wrapper}>
          <div className={css.info_tab_buttons_wrapper}>
            {tabs?.map((tab) => {
              return (
                <button
                  onClick={() => {
                    setSelectedTab(tab);
                  }}
                  className={`${css.tab_button} ${
                    selectedTab == tab ? css.active : ""
                  }`}
                >
                  {tab}
                </button>
              );
            })}
          </div>
          {selectedTab === "Overview" && (
            <div className={css.info}>
              <div className={css.infoLeft}>
                {showData?.map((data) => {
                  return (
                    <div>
                      {data?.key}
                      <strong>{data?.value}</strong>
                    </div>
                  );
                })}
              </div>
              <div className={css.infoRight}></div>
            </div>
          )}
          {selectedTab === "Transactions" && (
            <div className={css.infoTable}>
              <div className={css.filterButtons}>
                <div className={css.buttonGroup}>
                  <label id="filter_by">Filter by:</label>
                  <Select
                    labelId="Filter by"
                    id="filter_by"
                    value={transactionsOf?.of}
                    sx={{ width: 356, height: 38 }}
                    size="small"
                    onChange={(e) =>
                      setTransactionOf({
                        ...transactionsOf,
                        of: e?.target?.value
                      })
                    }
                  >
                    {filterOptions?.map((option) => {
                      return (
                        <MenuItem value={option?.value}>
                          {option?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <label id="filter_status">Status:</label>
                  <Select
                    labelId="Filter by"
                    id="filter_status"
                    value={transactionsOf?.status}
                    sx={{ width: 356, height: 38 }}
                    size="small"
                    onChange={(e) =>
                      setTransactionOf({
                        ...transactionsOf,
                        status: e.target.value
                      })
                    }
                  >
                    {statusOptions[transactionsOf?.of]?.map((option) => {
                      return (
                        <MenuItem value={option?.value}>
                          {option?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <CommonTable
                {...{ headings }}
                tableData={tableData}
                noPaginate={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailWIthTabs;
