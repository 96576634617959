import React, { useEffect, useState } from "react";
import CommonTable from "../../components/CommonTable";
import css from "../PurchaseOrder/PurchaseOrder.module.css";
import ButtonGroup from "../../components/Layout/ButtonGroup";
import Button from "../../components/Layout/Button";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { salesOrders } from "../../ApiHandler/SalesOrder.duck";
import { sortArray } from "../../components/Helper";

const SalesOrder = () => {
  const allStates = ["all", "draft", "closed", "shipped", "invoiced"];
  const [currentState, setCurrentState] = useState(allStates[0]);
  const [allSalesOrders, setSalesOrders] = useState([]);
  const navigate = useNavigate();
  const { Id } = useParams();
  const [apiData, setApiData] = useState();
  const [sortAsc, setSortAsc] = useState(false);
  const headings = [
    "date",
    "Sales order",
    "customer name",
    "reference #",
    "status",
    "shipped",
    "invoiced",
    "amount",
    "delivery date",
  ];
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const setSortedData = (key) => {
    let tableData = sortArray(apiData, key, `${sortAsc ? "asc" : ""}`);
    tableData = tableData?.map((o) => {
      let status =
        o.status == "closed" ? (
          <span className="active">{o.status}</span>
        ) : (
          <span className="inactive">{o.status}</span>
        );
      return [
        o.id,
        o.order_date,
        o.order_number,
        o.customer_name,
        o.reference,
        status,
        <div className={`dotStatus ${o.shipped ? "active" : ""}`}>&nbsp;</div>,
        <div className={`dotStatus ${o.invoiced ? "active" : ""}`}>&nbsp;</div>,
        o.amount,
        o.delivery_date,
      ];
    });
    setSalesOrders(tableData);
    setSortAsc(!sortAsc);
  };

  const fetchSalesOrder = async () => {
    const response = await salesOrders(currentState, page + 1);
    response?.data?.sales_orders?.map(
      (so) => (so.order_number = +so.order_number)
    );
    setApiData(response?.data?.sales_orders);
    let tableData = response?.data?.sales_orders?.map((o) => {
      let status =
        o.status == "closed" ? (
          <span className="active">{o.status}</span>
        ) : (
          <span className="inactive">{o.status}</span>
        );
      return [
        o.id,
        o.order_date,
        +o.order_number,
        o.customer_name,
        o.reference,
        status,
        <div className={`dotStatus ${o.shipped ? "active" : ""}`}>&nbsp;</div>,
        <div className={`dotStatus ${o.invoiced ? "active" : ""}`}>&nbsp;</div>,
        o.amount,
        o.delivery_date,
      ];
    });
    setSalesOrders([]);
    setSalesOrders(tableData);
    setTotal(response?.data?.total);
  };

  useEffect(() => {
    fetchSalesOrder();
  }, [currentState, Id]);

  if (Id) {
    return <Outlet />;
  }

  return (
    <div className={css.commonBox}>
      <div
        className={`${css.flex} justify_between align_center pageHeaderWrapper`}
      >
        <h2 className={`${css.pageHeader} ${css.m0}`}>Sales Orders</h2>
        <Button
          type="button"
          className={css.commonButton || "btnFill btn"}
          onClick={() => navigate("/sales-orders-new")}
        >
          New Sales Order
        </Button>
      </div>
      <ButtonGroup>
        {allStates?.map((state, ind) => {
          return (
            <Button
              type="button"
              key={ind}
              selected={currentState == state}
              onClick={() => setCurrentState(state)}
            >
              {state}
            </Button>
          );
        })}
      </ButtonGroup>
      <CommonTable
        {...{ headings, page, total, onPageChange, setSortedData }}
        tableData={allSalesOrders}
        sortKey={[
          { index: 0, key: "order_date" },
          { index: 1, key: "order_number" },
        ]}
      />
    </div>
  );
};

export default SalesOrder;
