import axios from "axios";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const fetchCategoriesApi = async (status, page, query) => {
    const response = await axios.get(`${BACKEND_URL}/allCategories`)
    return response?.data || []
};

const fetchCategoryEdit = async (Id) => {
    return await axios.get(
      `${BACKEND_URL}/category_edit/${Id}`
    );
}

const createCategoryApi = async (params) => {
    const response = await fetch(`${BACKEND_URL}/category`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
    });
    return await response.json();
};

const deleteCategory = async (Id) => {
    try{
      return await axios.delete(`${BACKEND_URL}/category/${Id}`);
    }catch(error){
      return {error: error}
    }
};



export {fetchCategoriesApi, fetchCategoryEdit, createCategoryApi, deleteCategory} 
