import React, { useEffect, useState } from "react";
import css from "../PurchaseOrder/PurchaseOrder.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { toast } from "react-toastify";
import CustomerSchema from "./CustomerSchema";
import { createCustomerApi, fetchCustomerEdit } from "../../ApiHandler/Customer.duck";

const CustomerNew = () => {
  const { Id } = useParams();
  const navigate = useNavigate()
  const [ editData, setEditData ] = useState({});

  const typesOptions = [
    {label: "Business", value: "business"},
    {label: "Individual", value: "individual"}
  ];

  const paymentTermOptions = [
    { label: "Upon Receipt", value: "upon_receipt" },
    { label: "Net 15", value: "net15" },
    { label: "Net 30", value: "net30" },
  ];

  const initValues = {
    name: "",
    email: "",
    phone: "",
    payment_terms: "upon_receipt",
    customer_type: 0,
  };

  const createCustomer = ()=>{
    let formData =  JSON.parse(JSON.stringify(formik.values));
    createCustomerApi(formData).then((response)=> {
      if(response.success){
        toast("Customer created successfully");
        navigate('/customers')
      }else{
        console.log("response.error",response.error);
        toast(response.error)
      }
    })
  }

  const formik = useFormik({
    initialValues: editData?.id ? editData : initValues,
    onSubmit: createCustomer,
    enableReinitialize: true,
    validationSchema: CustomerSchema
  });

  const handleCancel = ()=>{
    if(Id){
      navigate(`/customers/${Id}`)
    }else{
      navigate(`/customers`)
    }
  }

  const getEdit = async ()=>{
    if(Id){
      const response = await fetchCustomerEdit(Id);
      setEditData({});
      setEditData(response?.data?.edit_data);
    }
  }

  useEffect(()=>{
    getEdit();
  },[Id])
  return (
    <div className={css.commonBox}>
      <form onSubmit={formik.handleSubmit}>
        <h2 className={css.pageHeader}>{Id ? "Edit" : "New"} Customer</h2>
        <div className={css.formWrapper}>
        <div className={`${css.formRow} ${css.mb28}`}>
            <label id="customer_type_label" htmlFor="customer_type_id">
              Type
            </label>
            <Select
              labelId="customer_type_label"
              id="customer_type_id"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.customer_type}
              onChange={(e) =>
                formik.setFieldValue("customer_type", e?.target?.value)
              }
              error={formik?.errors?.customer_type}
              helperText={formik?.errors?.customer_type}
            >
              {typesOptions?.map((cat) => {
                return (
                  <MenuItem key={cat.value} value={cat.value}>
                    {cat.label}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className={css.formRow}>
            <label htmlFor="name">Name</label>
            <TextField
              type={"text"}
              id="name"
              name="name"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.name}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.name}
              helperText={formik?.errors?.name}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="name">Email</label>
            <TextField
              type={"text"}
              id="email"
              name="email"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.email}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.email}
              helperText={formik?.errors?.email}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="name">Phone</label>
            <TextField
              type={"text"}
              id="phone"
              name="phone"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.phone}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.phone}
              helperText={formik?.errors?.phone}
            />
          </div>
          <div className={`${css.formRow} ${css.mb28}`}>
            <label id="payment_terms_label" htmlFor="payment_terms">
              Payment terms
            </label>
            <Select
              labelId="payment_terms_label"
              id="payment_terms"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.payment_terms}
              onChange={(e) =>
                formik.setFieldValue("payment_terms", e?.target?.value)
              }
              error={formik?.errors?.payment_terms}
              helperText={formik?.errors?.payment_terms}
            >
              <MenuItem disabled value="0">
                Please select
              </MenuItem>
              {paymentTermOptions?.map((cat) => {
                return (
                  <MenuItem key={cat.value} value={cat.value}>
                    {cat.label}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          
        </div>
        <div className={css.footerActionButtons}>
          <button
                 className={`${css.primaryButton} btnFill btn`}
                type="submit"
          >
            Save
          </button>
          <button
            type="button"
           className={`${css.simpleButton} btnOutline btn`}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomerNew;
