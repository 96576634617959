const invoice = {
  id: "5df3180a09ea16dc4b95f910",
  invoice_no: "201906-28",
  balance: "2,283.74",
  company: "Ashok Supermarket",
  email: "mohalihood@mantri.com",
  phone: "6504520008",
  address: "123 road, Mohali,India",
  date: "2024-09-12",
  deliverydate: "2024-09-16",
  items: [
    {
      sno: 1,
      desc: "Dove Milk Chocolate",
      qty: 5,
      rate: 200,
    },
    {
      sno: 2,
      desc: "Cream Ice Cake",
      qty: 5,
      rate: 373.11,
    },
    {
      sno: 3,
      desc: "Pulses and Veggies",
      qty: 5,
      rate: 458.61,
    },
    {
      sno: 4,
      desc: "Butter and curd",
      qty: 10,
      rate: 725,
    },
    {
      sno: 5,
      desc: "Rice Hariyali",
      qty: 4,
      rate: 141.02,
    },
  ],
  purchaseOrder: {
    heading: "PURCHASE ORDER",
    id: "#PO-00150",
  },
};

export default invoice;
