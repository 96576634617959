import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import css from "./SalesOrder.module.css";
import { X } from "lucide-react";
import { searchItemsApi } from "../../ApiHandler/SalesOrder.duck";
import AddNewProduct from "../PurchaseOrder/AddNewProduct";

const ItemRow = (props) => {
  const {
    formik,
    item,
    itemIndex,
    allMajorItems,
    setAllMajorItems,
    addItem,
    setAddItem,
    defaultItems
  } = props;

  const [itemQuery, setItemQuery] = useState();
  const [selectedItem, setSelectedItem] = useState({ id: '', label: '' });
  const [allItems, setAllItems] = useState([]);

  useEffect(() => {
    if (defaultItems?.length > 0) {
      setAllItems(defaultItems)
    }
  }, [defaultItems])

  const searchItems = async () => {
    const response = await searchItemsApi(itemQuery);
    if (response?.data?.products?.length > 0) {
      setAllItems([...response?.data?.products]);
      setAllMajorItems([...allMajorItems, ...response?.data?.products]);
    }
  };

  const handleChange = (e, key = null) => {
    key ||= e.target.id;
    const items = formik?.values.items;
    items[itemIndex][key] = e.target.value;
    formik?.setFieldValue('items', items)
  };

  useEffect(() => {
    if (itemQuery) {
      searchItems();
    }
  }, [itemQuery]);
  const currentItem = () => {
    return allMajorItems?.find(
      (item) => item.id === formik?.values.items[itemIndex]?.vendor_product_id
    );
  };

  const removeRow = () => {
    let items = formik?.values.items;
    if (items?.length > 1) {
      items.splice(itemIndex, 1);
      formik.setFieldValue('items', [...items])
    }
  };

  const addNewRow = (e, val) => {
    setSelectedItem({ id: val?.id || '', label: val?.label || '' });
    let items = JSON.parse(JSON.stringify(formik?.values.items));
    items[itemIndex].vendor_product_id = val?.id;
    items[itemIndex].quantity = 1;
    let alreadySelected = items?.filter((item) => item.vendor_product_id);
    if (alreadySelected.length === items?.length) {
      items.push({ vendor_product_id: null, quantity: 0 });
    }
    document.getElementById(`quantity${itemIndex}`).focus();
    formik?.setFieldValue('items', items)
  };

  const setAutoComplete = () => {
    const items = allItems?.length > 0 ? allItems : defaultItems
    if (items.length > 0 && formik.values.items[itemIndex]) {
      let selectedItemObject = items?.find((i) => +i.id === +formik.values.items[itemIndex]?.vendor_product_id)
      const autoComVal = {
        id: selectedItemObject?.id || '',
        label: selectedItemObject?.label || ""
      }
      setSelectedItem({ ...autoComVal })
    }
  }

  useEffect(() => {
    setAutoComplete()
  }, [formik.values.items, defaultItems])

  return (
    <>
      {addItem && (
        <tr>
          <td>
            <AddNewProduct
              {...{
                formik,
                addNewRow,
                addItem,
                setAddItem,
                setAllItems,
                allItems,
                allMajorItems,
                setAllMajorItems,
                itemQuery,
                setItemQuery,
                selectedItem,
                setSelectedItem
              }}
              vendorId={formik.values?.vendor_id}
            />
          </td>
        </tr>
      )}
      <tr>
        <td colSpan="2">
          <div className={css.searchItem}>
            <Autocomplete
              disablePortal
              options={allItems?.map((p) => {
                return { id: p.id, label: p.label };
              })}
              noOptionsText={
                <div className={css.notItemAvailable}>
                  <span>No Item available</span>
                  {/* <button
                    className={css.addItembutton}
                    onClick={() => {
                      setAddItem(true);
                    }}
                  >
                    + Add an item
                  </button> */}
                </div>
              }
              clearOnBlur={false}
              sx={{ width: 300, padding: 0 }}
              size="small"
              onChange={(e, val) => {
                addNewRow(e, val);
              }}
              onInputChange={(event, val) => {
                setItemQuery(val);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  placeholder="Type or click to select an item"
                  variant="outlined"
                  value={itemQuery}
                  error={formik.errors.items}
                  helperText={formik.errors.items}
                />
              )}
              value={selectedItem}
            />
          </div>
        </td>
        <td className={css.qtySelectorRow}>
          <p>
            <input
              id={`quantity${itemIndex}`}
              type={`number`}
              placeholder="1.00"
              className={css.qtySelector}
              value={item?.quantity}
              min={1}
              onChange={(e) => handleChange(e, "quantity")}
            />
          </p>
          <p
            className={
              +item?.quantity > +currentItem()?.stock ? css.colorRed : ""
            }
          >
            Stock on hand: {currentItem()?.stock || 0.0}
          </p>
        </td>
        <td>
          <div className={css.itemLastRow}>
            $
            {currentItem()
              ? (+currentItem()?.price * +item?.quantity)?.toFixed(2)
              : "--"}
            <div className={css.removeRowButton} onClick={() => removeRow()}>
              <X />
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default ItemRow;
