import React from "react";

const ButtonGroup = ({children})=>{
    return(
        <div className="buttonGroups">
            {children}
        </div>
    )
}

export default ButtonGroup;