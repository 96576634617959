import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import invoice from "../data/invoice";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    marginTop: 24,
  },
  reportTitle: {
    color: "#61dafb",
    letterSpacing: 4,
    fontSize: 25,
    textAlign: "center",
    textTransform: "uppercase",
  },
});

const InvoiceTitle = ({ title }) => (
  <>
    <View>
      <Text>{invoice.address}</Text>
      <Text>{invoice.phone}</Text>
      <Text>{invoice.email}</Text>
    </View>
  </>
);

export default InvoiceTitle;
