import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Comic Sans MS, sans-serif",
        },
        h1: {
          fontSize: "2.5rem",
          color: "green",
        },
        p: {
          fontSize: "1.4rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          fontSize: "1.4rem",
        },
      },
    },
  },
});
