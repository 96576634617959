import { AccountBox } from "@mui/icons-material";

const SLIDER_LISTING1 = [
  {
    id: 0,
    label: "Products",
    icon: <AccountBox />,
    link: "/products",
  },
  {
    id: 1,
    label: "Sales Orders",
    icon: <AccountBox />,
    link: "/sales-orders",
  },
  {
    id: 2,
    label: "Customers",
    icon: <AccountBox />,
    link: "/customers",
  },
  {
    id: 3,
    label: "Purchase Orders",
    icon: <AccountBox />,
    link: "/purchase",
  },
  {
    id: 4,
    label: "Vendors",
    icon: <AccountBox />,
    link: "/vendors",
  },
  {
    id: 5,
    label: "Tariff",
    icon: <AccountBox />,
    link: "/tariffs",
  },
  {
    id: 6,
    label: "Users",
    icon: <AccountBox />,
    link: "/users",
  },
  {
    id: 7,
    label: "Stock adjustment",
    icon: <AccountBox />,
    link: "/adjustments",
  },
  {
    id: 7,
    label: "Category",
    icon: <AccountBox />,
    link: "/category",
  },
];

const SLIDER_LISTING2 = [
  {
    id: 0,
    label: "Profile Settings",
    icon: <AccountBox />,
    link: "/",
  },
];

// Logic from provided code snippet
SLIDER_LISTING1.forEach((sliderObj) => {
  if (sliderObj.children) {
    sliderObj.link = sliderObj.children[0].link;
  }
});

export { SLIDER_LISTING1, SLIDER_LISTING2 };
