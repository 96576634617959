import * as Yup from "yup";

const ProductSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter product name"),
  product_category_id: Yup.number().required("Please select category").positive("Please select category"),
  ups: Yup.string(),
  unit: Yup.string().notOneOf(["0"], 'Please select unit').required("Please select unit"),
  physical_stock: Yup.number()
    .typeError("Please enter a valid number")
    .min(0, "Number must be greater than 0")
    .required("Opening stock is required"),
  reorder_level: Yup.number()
    .typeError("Please enter a valid number")
    .positive("Number must be greater than 0")
    .required("Reordering level is required"),
  selling_price: Yup.number()
    .typeError("Please enter a valid number")
    .positive("Number must be greater than 0")
    .required("Selling price is required"),
  purchase_price: Yup.number()
    .typeError("Please enter a valid number")
    .positive("Number must be greater than 0")
    .required("Purchasing price is required"),
});

export default ProductSchema;
