import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#454545",
    backgroundColor: "#454545",
    // borderBottomWidth: 1,
    alignItems: "center",
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,    
    padding: "8px 10px 4px",
    verticalAlign: "middle",
  },
  description: {
    width: "60%",
    textAlign: "left",
    marginLeft: 50,
    color: "#fff",
  },
  hash: {
    color: "#fff",
  },
  qty: {
    width: "10%",
    color: "#fff",
  },
  rate: {
    width: "15%",
    color: "#fff",
  },
  amount: {
    width: "15%",
    color: "#fff",
  },
});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.hash}>#</Text>
    <Text style={styles.description}>Item & Description</Text>
    <Text style={styles.qty}>Qty</Text>
    <Text style={styles.rate}>Unit Price</Text>
    <Text style={styles.amount}>Amount</Text>
  </View>
);

export default InvoiceTableHeader;
