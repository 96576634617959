import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Detail from "../../components/Detail/Detail";
import { Button } from "@mui/material";

const CategoryShow = () => {
  const { Id } = useParams();
  const [categoryData, setCategoryData] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const navigate = useNavigate();
  const categoryShow = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/category/${Id}`, {
      method: "GET",
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.category) {
          setCategoryData(data?.category);
        }
      });
  };


  useEffect(() => {
    categoryShow();
  }, []);
  const getformattedPurchaseData = () => {
    return [
      { key: "Category Name", value: categoryData?.name }
    ];
  };

  const handleDelete = async () => {    
    let response = {};
    if (response?.data?.success) {
      toast("Deleted Successfully");
      navigate("/");
    } else {
      toast(response?.data?.error);
    }
  };

  const actionButtons = [
    <Button
      type="button"
      variant="contained"
      onClick={() => {
          navigate(`/categoriesNew/${Id}`);
      }}
    >
      Edit
    </Button>
    ,
    <Button
      type="button"
      variant="contained"
      onClick={() => setShowDelete(true)}
    >
      Delete
    </Button>
  ]
  return (
    <>
      <Detail
        key={categoryData?.length}
        showData={getformattedPurchaseData()}
        salesData={categoryData}
        backUrl="/category"
        handleDelete={handleDelete}
        editUrl={`/categoriesNew/${Id}`}
        actionButtons={actionButtons}
        showDelete={showDelete}
        setShowDelete={setShowDelete}
      />
    </>
  );
};

export default CategoryShow;
