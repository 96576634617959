import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./Sidebar/SideBar";
import { useLocation } from "react-router-dom";
import SignIn from "../../Pages/SignIn/SignIn";
import AuthComponent from "../../Pages/SignIn/AuthComponent";

const AppLayout = ({ children, currentRole, setCurrentRole, authLoaded }) => {
  const [open, setOpen] = useState(true);
  const location = useLocation();
  return (
    <>
      {currentRole === "admin" ? (
        <SideBar {...{ open, setOpen, location, currentRole }}>
          <Header open={open} setOpen={setOpen} />
          <div className="victors-container">
            <div className={`victors-body-content`}>{children}</div>
          </div>
          {/* <Footer /> */}
        </SideBar>
      ) : currentRole ? (
        <SideBar {...{ open, setOpen, location, currentRole }}>
          <Header open={open} setOpen={setOpen} />
          <div className="victors-container">
            <div className={`victors-body-content`}>{children}</div>
          </div>
        </SideBar>
      ) : (
        authLoaded && <AuthComponent {...{ currentRole, setCurrentRole }} />
      )}
    </>
  );
};

export default AppLayout;
