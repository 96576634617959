import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { createVendorAPi, fetchCatgoriesApi } from "../../ApiHandler/PurchaseOrder.duck";
import css from "./SalesOrder.module.css";
import { toast } from "react-toastify";
import { createCustomerApi } from "../../ApiHandler/SalesOrder.duck";

const AddNewCustomer = (props) => {
  const { formik, addCustomer, setAddCustomer, customerQuery ,setAllCustomers, allCustomers, selectedCustomer, setSelectedCustomer} = props;
  const [formData, setFormData] = useState({name: customerQuery});

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = ()=>{
    if(!formData?.name){
      toast("Please enter customer name!")
      return;
    }
    createCustomerApi(formData).then((response)=> {
      if(response.success){
        toast("Customer created successfully");
        setAddCustomer(false);
        formik.setFieldValue('customer_id',response?.customer?.id)
        setAllCustomers([...allCustomers, {id: response.customer.id, label: response.customer.name}]);
        setSelectedCustomer({id: response.customer.id, label: response.customer.name});
        //change focus
        document.getElementById(`order_date`).focus();
      }else{
        console.log("response.error",response.error);
        toast(response.error)
      }
    })
  }

  return (
    <>
      <Modal
        open={addCustomer}
        onClose={() => setAddCustomer(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="addProductForm addNewVendorModal" sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add new Customer
          </Typography>
          <div className="addFormContent">
            <div className={`${css.formRow} PurchaseOrderformRow`}>
              <label htmlFor="name">Name</label>
              <input
                type={"text"}
                id="name"
                value={formData?.name}
                onChange={(e) => handelChange(e)}
              />
            </div>
          </div>
          <div className="ModalFooter">
            <button type="button" className={css.primaryButton} onClick={()=> handleSubmit()}>Save</button>
            <button type="button" className={css.destructiveButton} onClick={()=> setAddCustomer(false)}>Cancel</button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddNewCustomer;
