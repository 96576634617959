import React, { useEffect, useState } from "react";
import AddNewProduct from "./AddNewProduct";
import ItemRow from "./ItemRow";
import css from "./PurchaseOrder.module.css";
import { FormHelperText } from "@mui/material";
import { autofillLowStockApi } from "../../ApiHandler/PurchaseOrder.duck";
import { toast } from "react-toastify";


const ItemTable = (props) => {
  const { itemInitValue, getTotal, allMajorItems, setAllMajorItems, formik, editData,defaultItems, selectedVendor,setAllItems } =
    props;

  const [addItem, setAddItem] = useState();
  const addMoreItem = () => {
    const items = formik.values?.items;
    items.push(itemInitValue);
    formik.setFieldValue("items", items);
  };

  const autofillLowStock = async () => {
    if(selectedVendor?.id){
      const result = await autofillLowStockApi(selectedVendor?.id);
      let products = result?.data?.products
      if(products?.length > 0){
        setAllMajorItems([...result?.data?.products])
        setAllItems([...result?.data?.products])
        let formattedData = products?.map((p)=>{ return {vendor_product_id: p.id, quantity: p.stock}})
        formik.setFieldValue("items", formattedData)
      }else{
        toast("No such products found");
      }
    }else{
      formik.validateField('vendor_id');
      toast("Please select vendor first!");
    }
  }
  return (
    <div className={css.itemTableWrapper}>
      <div className={css.tableMainHeader}>
        <h2 className={css.m0}>Item Table</h2>
      </div>
      <div className="table_wrapper"> 
      <table className={css.table}>
        <thead>
          <tr>
            <th colSpan="2" className={`${css.capitalize} textLeft`}>
              Item Details
            </th>
            <th className={css.capitalize}>Quantity</th>
            <th className={css.capitalize}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {formik.values?.items?.map((item, itemIndex) => {
            return (
              <>
                <ItemRow
                  key={`${itemIndex}itemrow`}
                  {...{
                    item,
                    itemIndex,
                    allMajorItems,
                    setAllMajorItems,
                    itemInitValue,
                    addItem,
                    setAddItem,
                    formik,
                    editData,
                    defaultItems

                  }}
                />
              </>
            );
          })}
        </tbody>
      </table>
      </div>
      <div className={css.addNewRow}>
        <button
          type="button"
          className={css.commonButton || ""}
          onClick={() => addMoreItem()}
        >
          Add New Row
        </button>
        <button
          type="button"
          className={css.commonButton || ""}
          onClick={() => autofillLowStock()}
        >
          Autofill with low stock items
        </button>
      </div>
      <div className={css.tableFooter}>
        <div className={css.totalQuantityWrapper}>
          <strong>Total Items: </strong>{" "}
          {formik.values?.items?.reduce(
            (total, obj) => +obj.quantity + +total,
            0
          )}
        </div>
        <div
          className={`${css.totalWrapper} ${(css.flex, css.justifyBetween)}`}
        >
          <p>Total</p>
          <p>${getTotal()}</p>
        </div>
      </div>
    </div>
  );
};

export default ItemTable;
