import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const fetchCatgoriesApi = async () => {
  const response = await axios.get(`${BACKEND_URL}/allCategories`);
  return response?.data?.categories || [];
};

const fetchVendorsApi = async (query) => {
  try {
    return await axios.get(`${BACKEND_URL}/searchVendors?query=${query}`);
  } catch (e) {
    console.log("Something went wrong", e);
  }
};

const searchItemsApi = async (vendorId, query) => {
  return await axios.get(
    `${BACKEND_URL}/searchVendorProducts?query=${query || ""}&vendor_id=${
      vendorId || ""
    }`
  );
};

const purchaseOrders = async (query, page) => {
  return await axios.get(
    `${BACKEND_URL}/purchase_orders?status=${query || ""}&page=${page}`
  );
};

const createPurchaseOrderApi = async (params) => {
  const response = await fetch(`${BACKEND_URL}/purchase_orders`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json" },
  });
  return await response.json();
};

const createPurchaseReceivedApi = async (params) => {
  const response = await fetch(`${BACKEND_URL}/purchase_received`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json" },
  });
  return await response.json();
};

const createProductApi = async (params) => {
  const response = await fetch(`${BACKEND_URL}/products`, {
    method: "POST",
    body: JSON.stringify({ product: params }),
    headers: { "Content-Type": "application/json" },
  });
  return await response.json();
};

const createVendorAPi = async (params) => {
  const response = await fetch(`${BACKEND_URL}/vendors`, {
    method: "POST",
    body: JSON.stringify({ vendor: params }),
    headers: { "Content-Type": "application/json" },
  });
  return await response.json();
};

const markConfirmAPi = async (Id) => {
  return await axios.get(`${BACKEND_URL}/purchase_order/${Id}/mark_confirm`);
};

const deletePurchasaeOrder = async (Id) => {
  try {
    return await axios.delete(`${BACKEND_URL}/purchase_orders/${Id}`);
  } catch (error) {
    return { error: error };
  }
};

const purchaseEditOrders = async (id) => {
  return await axios.get(`${BACKEND_URL}/edit_purchase_orders/${id}`);
};

const purchaseReceived = async (id) => {
  return await axios.get(`${BACKEND_URL}/get_purchase_received/${id}`);
};

const autofillLowStockApi = async (Id) => {
  return await axios.get(`${BACKEND_URL}/autofill_low_stock/${Id}`);
};

export {
  purchaseEditOrders,
  fetchVendorsApi,
  searchItemsApi,
  createPurchaseOrderApi,
  createPurchaseReceivedApi,
  fetchCatgoriesApi,
  createProductApi,
  createVendorAPi,
  purchaseOrders,
  markConfirmAPi,
  deletePurchasaeOrder,
  purchaseReceived,
  autofillLowStockApi,
};
