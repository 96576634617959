const sortArray = (arr, key, toSort = 'asc') => {
    return arr?.sort((a, b) => {
      let valA = a[key];
      let valB = b[key];
  
      // Check if the values are numbers or strings
      if (typeof valA === 'number' && typeof valB === 'number') {
        // Sorting numbers
        return toSort === 'asc' ? valA - valB : valB - valA;
      } else {
        // Sorting strings, case-insensitively
        valA = String(valA).toLowerCase();
        valB = String(valB).toLowerCase();
        if (valA < valB) {
          return toSort === 'asc' ? -1 : 1;
        } else if (valA > valB) {
          return toSort === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      }
    });
  }
  
  export { sortArray };
  