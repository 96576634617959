import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Detail from "../../components/Detail/Detail";
import { salesDelivery } from "../../ApiHandler/SalesOrder.duck";

const SalesDeliveryShow = (props) => {
  const { Id } = useParams();
  const [salesData, setSalesData] = useState({});
  const [tableData, setTableData] = useState([]);

  const purchaseOrderShow = async () => {
    const response = await salesDelivery(Id);
    const salesOrder = response?.data?.sales_delivery;
    if (salesOrder) {
      salesOrder.name = "Sales Delivery";
      setSalesData({ ...salesOrder });
      const tbdata =
        salesOrder?.items?.map((p) => [p.name, p.received_qty]) || [];
      setTableData([...tbdata]);
    }
  };
  useEffect(() => {
    purchaseOrderShow();
  }, []);

  const headings = ["ITEM DETAILS", "QTY RECEIVED"];
  const getformattedPurchaseData = () => {
    return [
      { key: "Sales Order", value: salesData?.formatted_order_number },
      { key: "Customer", value: salesData?.customer_name },
      {
        key: "Date Shipped",
        value: salesData?.date_delivered,
      },
    ];
  };

  const getTotal = () => {
    return {
      totalQty:
        salesData?.items?.reduce(
          (total, obj) => +obj.received_qty + +total,
          0
        ) || 0,
    };
  };
  return (
    <>
      <Detail
        name={"Sales Delivery"}
        showData={getformattedPurchaseData()}
        salesData={salesData}
        headings={headings}
        tableData={tableData}
        actionButtons={[]}
        backUrl={`/purchase/${Id}`}
        getTotal={getTotal}
        showTotalItems={true}
      />
    </>
  );
};

export default SalesDeliveryShow;
