import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#454545",
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    textAlign: "right",
    paddingRight: 8,
    width: "60%"
  },
  total: {
    color: "#000",
    marginLeft: "auto",
  },
  rowTotal: { 
    width: "250px",
    color: "#000",
    backgroundColor: "#ddd",
    height: "35px",
    paddingRight: "8px",
    paddingLeft: "55px",
    textAlign: "right"
  },
  subtotal: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
    fontSize: "14px",
  },
  tableContainer: {
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-end",
    width: "100%",    
    marginTop: "5px"
  },
});

const InvoiceTableFooter = ({ totalAmount }) => {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.row}>
        <Text style={styles.description}>Sub total</Text>
        <Text style={styles.subtotal}>${Number.parseFloat(totalAmount).toFixed(2)}</Text>
      </View>
      <View style={[styles.row, styles.rowTotal]}>
        <Text style={styles.description}>Total</Text>
        <Text style={styles.total}>${Number.parseFloat(totalAmount).toFixed(2)}</Text>
      </View>
    </View>
  );
};

export default InvoiceTableFooter;
