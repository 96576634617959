import React, { useEffect, useState } from "react";
import CommonTable from "../../components/CommonTable";
import css from "../PurchaseOrder/PurchaseOrder.module.css";
import ButtonGroup from "../../components/Layout/ButtonGroup";
import Button from "../../components/Layout/Button";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { fetchProductsApi } from "../../ApiHandler/Products.duck";
import { sortArray } from "../../components/Helper";

const Products = () => {
  const allStates = ["all", "active", "inactive"];
  const [apiData, setApiData] = useState();
  const [currentState, setCurrentState] = useState(allStates[0]);
  const [allProducts, setProducts] = useState([]);
  const [sortAsc, setSortAsc] = useState(false);
  const navigate = useNavigate();
  const { Id } = useParams();
  const headings = ["Product Name", "Stock on hand"];
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const fetchProducts = async () => {
    const response = await fetchProductsApi(currentState, page + 1);
    setApiData(response?.data?.products || []);
    let tableData = response?.data?.products?.map((o) => {
      return [o.id, o.name, o.stock];
    });

    setProducts([]);
    setProducts(tableData);
    setTotal(response?.data?.total);
  };

  const setSortedData = (key) => {
    let tableData = sortArray(apiData, key, `${sortAsc ? "asc" : ""}`);
    tableData = tableData?.map((o) => {
      return [o.id, o.name, o.stock];
    });
    setProducts(tableData);
    setSortAsc(!sortAsc);
  };

  useEffect(() => {
    fetchProducts();
  }, [currentState, Id]);

  if (Id) {
    return <Outlet />;
  }

  return (
    <div className={css.commonBox}>
      <div
        className={`${css.flex} justify_between align_center pageHeaderWrapper`}
      >
        <h2 className={`${css.pageHeader} ${css.m0}`}>Products</h2>
        <Button
          type="button"
          className={css.commonButton || "btnFill btn"}
          onClick={() => navigate("/productsNew")}
        >
          New Product
        </Button>
      </div>
      <ButtonGroup>
        {allStates?.map((state, ind) => {
          return (
            <Button
              type="button"
              key={ind}
              selected={currentState == state}
              onClick={() => setCurrentState(state)}
            >
              {state}
            </Button>
          );
        })}
      </ButtonGroup>
      <CommonTable
        {...{ headings, page, total, onPageChange, setSortedData }}
        tableData={allProducts}
        inactiveWholeRow={apiData}
        sortKey={[
          { index: 1, key: "stock" },
          { index: 0, key: "name" },
        ]}
      />
    </div>
  );
};

export default Products;
