import React, { useEffect, useState } from "react";
import AddNewProduct from "./AddNewProduct";
import ItemRow from "./ItemRow";
import css from "./PurchaseOrder.module.css";
import { FormHelperText, TextField, TextareaAutosize } from "@mui/material";
import "./ItemTableRecieve.css";

const ItemTableRecieve = (props) => {
  const { itemInitValue, allMajorItems, setAllMajorItems, formik, editData,defaultItems, items, type } =
    props;

  const [addItem, setAddItem] = useState();
  const addMoreItem = () => {
    const items = formik.values?.items;
    items.push(itemInitValue);
    formik.setFieldValue("items", items);
  };

  const handleQtyChange=(value, index)=>{
    const items = formik.values?.items;
    items[index].received_qty = value;
    formik.setFieldValue("items", items);
  }
  return (
    <div className={css.itemTableWrapper}>
      <div className="table_wrapper"> 
      <table className={css.table}>
        <thead>
          <tr>
            <th colSpan="2" className={`${css.capitalize} textLeft`}>
              Item Details
            </th>
            <th className={css.capitalize}>ORDERED</th>
            <th className={css.capitalize}>{type == "sales_order" ? "SHIPPED" : "RECEIVED"}</th>
          </tr>
        </thead>
        <tbody>
          {formik.values?.items?.map((item, itemIndex) => {
            return (
              <>
                <tr>
                  <td colSpan="2">{item?.name}</td>
                    <td>{item?.quantity}</td>
                    <td>
                    <TextField
                      label=""
                      type="text"
                      id={`received_qty${itemIndex}`}
                      name="received_qty"
                      variant="outlined"
                      onChange={(e)=> handleQtyChange(e.target.value, itemIndex)}
                      value={`${item.received_qty || 0}`}
                      size="small"
                    />
                    </td>
                </tr>
              </>
           );
          })}
        </tbody>
      </table>
      </div>
      <div className={css.tableFooter}>
        <div
          className={`${css.totalWrapper} ${(css.flex, css.justifyBetween)}`}
        >
          <p>Total Items:</p>
          <p>
            {formik.values?.items?.reduce(
              (total, obj) => +obj.received_qty + +total,
              0
            ) || 0}
          </p>
        </div>
      </div>
      <div className="notesWrapper">
          <strong>Notes </strong>{" "}
          <TextareaAutosize aria-label="empty textarea" placeholder="Empty" style={{minWidth: "800px", minHeight: "100px"}} value={formik?.values?.note} onChange={(e)=>  formik.setFieldValue("note", e.target.value)}/>
        </div>
    </div>
  );
};

export default ItemTableRecieve;
