import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const fetchTariffsApi = async (status, page) => {
    return await axios.get(
      `${BACKEND_URL}/tariffs?status=${status}`
    );
};

const deleteTariff = async (Id) => {
  try{
    return await axios.delete(`${BACKEND_URL}/tariffs/${Id}`);
  }catch(error){
    return {error: error}
  }
};


const createTariffApi = async (params) => {
  const response = await fetch(`${BACKEND_URL}/tariffs`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json" },
  });
  return await response.json();
};

const fetchTariffEdit = async (Id) => {
  return await axios.get(
    `${BACKEND_URL}/tariff_edit/${Id}`
  );
}

const searchTariffsApi = async (query) => {
  return await axios.get(`${BACKEND_URL}/searchTariffs?query=${query || ""}`);
};

export {fetchTariffsApi , deleteTariff, fetchTariffEdit, searchTariffsApi, createTariffApi};