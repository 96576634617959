import React, { useEffect, useState } from "react";
import css from "../PurchaseOrder/PurchaseOrder.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import {
  createCategoryApi,
} from "../../ApiHandler/Categories.duck";
import CategorySchema from "./CategorySchema";
import { toast } from "react-toastify";
import { fetchCategoryEdit } from "../../ApiHandler/Categories.duck.js";
import { fetchTariffsApi, searchTariffsApi } from "../../ApiHandler/Tariffs.duck";

const CategoryNew = () => {
  const { Id } = useParams();
  const navigate = useNavigate()
  const [ editData, setEditData ] = useState({});

  const initValues = {
    name: "",
  };

  const createCategory = ()=>{
    let formData =  JSON.parse(JSON.stringify(formik.values));
    createCategoryApi({product_category: formData}).then((response)=> {
      if(response.success){
        toast("Category created successfully");
        handleCancel();
      }else{
        console.log("response.error",response.error);
        toast(response.error)
      }
    })
  }

  const getEdit = async ()=>{
    if(Id){
      const response = await fetchCategoryEdit(Id);
      setEditData({});
      setEditData(response?.data?.edit_data);
    }
  }

  useEffect(() => {
    getEdit();
  }, []);

  const formik = useFormik({
    initialValues: editData?.id ? editData : initValues,
    onSubmit: createCategory,
    validationSchema: CategorySchema,
    enableReinitialize: true,
  });

  const handleCancel = ()=>{
    if(Id){
      navigate(`/category/${Id}`)
    }else{
      navigate(`/category`)
    }
  }
  return (
    <div className={css.commonBox}>
      <form onSubmit={formik.handleSubmit}>
        <h2 className={css.pageHeader}>{Id ? "Edit" : "New"} Category</h2>
        <div className={css.formWrapper}>
          <div className={css.formRow}>
            <label htmlFor="name">Name</label>
            <TextField
              type={"text"}
              id="name"
              name="name"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.name}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.name}
              helperText={formik?.errors?.name}
            />
          </div>
        </div>
        <div className={css.footerActionButtons}>
          <button
            className={`${css.primaryButton} btnFill btn`}
            type="submit"
          >
            Save
          </button>
          <button
            type="button"
           className={`${css.simpleButton} btnOutline btn`}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default CategoryNew;
