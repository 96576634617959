import React, { useEffect, useState } from "react";
import ItemRow from "./ItemRow";
import css from "./SalesOrder.module.css";

const ItemTable = (props) => {
  const { formik, formData, setFormData, defaultItems, itemInitValue,allMajorItems, setAllMajorItems, getTotal } = props;

  const [addItem,setAddItem] = useState();
  const addMoreItem = () => {
    const items = formik?.values?.items;
    items.push(itemInitValue);
    formik.setFieldValue('items', items);
  };

  return (
    <div className={css.itemTableWrapper}>
      <div className={css.tableMainHeader}>
        <h2 className={css.m0}>Item Table</h2>
      </div>
      <div className="table_wrapper"> 
      <table className={css.table}>
        <thead>
          <tr>
          <th colSpan="2" className={`${css.capitalize} textLeft`}>
              Item Details
            </th>
            <th className={css.capitalize}>Quantity</th>
            <th className={css.capitalize}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {formik?.values?.items?.map((item, itemIndex) => {
            return (
              <ItemRow
                {...{
                  formData,
                  item,
                  itemIndex,
                  setFormData,
                  allMajorItems,
                  setAllMajorItems,
                  itemInitValue,
                  addItem,setAddItem,
                  formik,
                  defaultItems
                }}
              />
            );
          })}
        </tbody>
      </table>
      </div>
      <div className={css.addNewRow}>
        <button 
          type="button"
          className={css.commonButton || ""}
          onClick={() => addMoreItem()}
        >
          Add New Row
        </button>
      </div>
      <div className={css.tableFooter}>
        <div className={css.totalQuantityWrapper}>
          <strong>Total Items: </strong> {formik?.values?.items?.reduce((total, obj)=> +obj.quantity + +total, 0)}
        </div>
        <div
          className={`${css.totalWrapper} ${(css.flex, css.justifyBetween)}`}
        >
          <p>Total</p>
          <p>${getTotal()}</p>
        </div>
      </div>
    </div>
  );
};

export default ItemTable;
