import React, { Fragment } from "react";
import { Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import PdfLogo from "../pdf-logo-new.png";

const styles = StyleSheet.create({
  invoiceInfoContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  label: {
    width: 60,
  },
  info: {
    fontSize: 12,
  },
  purchaseOrder: {
    flexDirection: "column",
    justifyContent: "flex-start",
    
  },
  purchase: {
    fontSize: 25,
    marginRight: 0,
    textAlign: "right",
    marginBottom: "-10px",
    textTransform: "capitalize"
  },
  orderid: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: "bold",
    color: "#000"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "strech",
    flexGrow: 0,
    flexShrink: 0,
    textAlign: "left"
  },
  reportTitle: {
    color: "#61dafb",
    letterSpacing: 4,
    fontSize: 25,
    textAlign: "center",
    textTransform: "uppercase",
    marginRight: "auto"
  },
  logo: {
    width: "150px",
    objectFit: "cover"
  }
  ,titleContainer: {
    padding: "0px 0 10px 15px",
    marginTop: "-10px",
  }
});

const InvoiceInfo = ({ invoice, pdfHeading, orderNumber }) => (
  <Fragment>
    <View style={styles.purchaseOrder}>
      <Text style={styles.purchase}>{pdfHeading}</Text>
      <View style={[styles.row, {marginBottom: "10px"}]} >
        <View style={styles.titleContainer}>
          <Image style={styles.logo} src={PdfLogo} />
        </View>
        <Text style={styles.orderid}>{orderNumber}</Text>
      </View>
    </View>
   
  </Fragment>
);

export default InvoiceInfo;
