import axios from "axios"
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const loginUser = async (params)=>{
    return await axios.post(
        `${BACKEND_URL}/auth/login`,
        params
    ).catch(e => {return {error: e}});
}

const getCurrentUserApi = async ()=>{
    return await axios.get(
        `${BACKEND_URL}/get_current_user`, {
            headers: {
                "Authorization": localStorage.getItem("xtedatr")
            }
        }
    ).catch(e => {return {error: e}});
}

export {loginUser , getCurrentUserApi}