import { Link, useLocation } from "react-router-dom";
import css from "./Sidebar.module.css";

const SidebarLink = ({ label, link, icon }) => {
  const location = useLocation();
  const isActive =
    location.pathname === link ||
    (link !== "/" && location.pathname.startsWith(link));

  return (
    <li className={isActive ? css.activeLink : ""}>
      <Link to={link}>
        {icon}
        {label}
      </Link>
    </li>
  );
};
export default SidebarLink;
