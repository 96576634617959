import React, { useEffect, useState } from "react";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import css from "./SalesOrder.module.css";
import moment from "moment";
import AddNewCustomer from "./AddNewCustomer";
import { toast } from "react-toastify";
import ItemTable from "./ItemTable";
import {
  createSalesOrderApi,
  fetchCustomersApi,
  salesEditOrders,
} from "../../ApiHandler/SalesOrder.duck";
import { useFormik } from "formik";
import SalesOrderSchema from "./SalesOrderSchema";
import { useNavigate, useParams } from "react-router-dom";

const SalesOrderNew = () => {
  const { Id } = useParams();
  const [editData, setEditData] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [allMajorItems, setAllMajorItems] = useState([]);

  const getTotal = () => {
    let total = 0;
    formik?.values?.items?.map((fi) => {
      let currentItem = allMajorItems?.find(
        (item) => +item.id === +fi?.vendor_product_id
      );
      total += currentItem?.price * fi.quantity || 0;
    });
    return total?.toFixed(2);
  };

  const paymentTermOptions = [
    { label: "Upon Receipt", value: "upon_receipt" },
    { label: "Net 15", value: "net15" },
    { label: "Net 30", value: "net30" },
  ];
  const itemInitValue = { vendor_product_id: "", quantity: 0 };
  const initValues = {
    items: [itemInitValue],
    payment_terms: paymentTermOptions[0]?.value,
    order_date:  moment(new Date()).format("YYYY-MM-DD"),
    expected_shipment_date:  moment(new Date()).format("YYYY-MM-DD"),
    reference: "",
    status: "draft",
  };
  const [formData, setFormData] = useState(initValues);
  const [customerQuery, setCustomerQuery] = useState();
  const [allCustomers, setAllCustomers] = useState([]);
  const [addCustomer, setAddCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({
    label: "",
    id: "",
  });
  const handleBeforeUnload = (event) => {
    // Standard message for most browsers
    const message = "You have unsaved changes. Are you sure you want to leave?";
    event.returnValue = message; // Chrome requires returnValue to be set
    return message;
  };
  const navigate = useNavigate();
  window.addEventListener("beforeunload", handleBeforeUnload);

  const fetchEdit = async () => {
    const response = await salesEditOrders(Id);
    const salesorder = response?.data?.sales_order
    if(salesorder?.sales_order){
      setAllItems([...salesorder?.item_options]);
      setAllMajorItems([...salesorder?.item_options]);
      setEditData({ ...salesorder?.sales_order });
      setAllCustomers([...salesorder?.customer_options]);
      setSelectedCustomer({...salesorder?.customer_options[0]})
    }
  };
  
  useEffect(() => {
    if (Id) {
      fetchEdit();
    }
  }, []);


  const createSalesOrder = () => {
    let formdata = JSON.parse(JSON.stringify(formik.values));
    formdata.items = formdata?.items.filter((itm) => !!itm.vendor_product_id);
    formdata.total = getTotal();
    let params = { sales_order: formdata };
    createSalesOrderApi(params).then((response) => {
      if (response.success) {
        toast("Sales order successfully");
        formik.resetForm();
        navigate("/sales-orders");
      } else {
        console.log("response.error", response.error);
        toast(response.error);
      }
    });
  };

  const formik = useFormik({
    initialValues: editData?.id ? editData : { ...initValues },
    onSubmit: createSalesOrder,
    validationSchema: SalesOrderSchema,
    enableReinitialize: true
  });

  const fetchCustomers = async () => {
    let response = await fetchCustomersApi(customerQuery);
    if (response?.data?.customers?.length > 0) {
      setAllCustomers([...response?.data?.customers]);
    }
  };

  const handleCancel = () => {
    if(Id){
      window.location.replace(`/sales-orders/${Id}`);
    }else{
      window.location.replace(`/sales-orders`);
    }
  };

  useEffect(() => {
    if (customerQuery) {
      fetchCustomers();
    }
  }, [customerQuery]);

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  return (
    <div className={css.commonBox}>
      {addCustomer && (
        <AddNewCustomer
          {...{
            addCustomer,
            setAddCustomer,
            allCustomers,
            setAllCustomers,
            customerQuery,
            selectedCustomer,
            setSelectedCustomer,
            formik,
          }}
        />
      )}
      <h2 className={css.pageHeader}>{Id ? "Edit" : "New"} Sales Order</h2>
      <div className={css.formWrapper}>
        <div className={`${css.formRow} ${css.mb28}`}>
          <label htmlFor="customer">Customer</label>
          <Autocomplete
            className={"customerClass"}
            disablePortal
            id="customer"
            options={allCustomers}
            sx={{ width: 356, height: 38 }}
            size="small"
            onChange={(e, val) => {
              formik.setFieldValue("customer_id", val?.id);
              setSelectedCustomer(val);
            }}
            onInputChange={(event, val) => {
              if (!val) {
                setAllCustomers([...[]]);
              }
              setCustomerQuery(val);
            }}
            value={selectedCustomer}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customers"
                variant="outlined"
                value={customerQuery}
                error={formik?.errors?.customer_id}
                helperText={formik?.errors?.customer_id}
              />
            )}
            noOptionsText={
              <div className={css.notItemAvailable}>
                <span>No customer found</span>
                <button
                  type="button"
                  className={css.addItembutton}
                  onClick={() => {
                    setAddCustomer(true);
                  }}
                >
                  + Add new customer
                </button>
              </div>
            }
          />
        </div>
        <div className={css.formRow}>
          <label htmlFor="order_date">Reference #</label>
          <TextField
            type={"text"}
            id="reference"
            name="reference"
            value={formik?.values?.reference}
            onChange={formik.handleChange}
            sx={{ width: 356 }}
            size="small"
            error={formik?.errors?.reference}
            helperText={formik?.errors?.reference}
          />
        </div>
        <div className={css.formRow}>
          <label htmlFor="order_date">Date</label>
          <TextField
            type={"date"}
            id="order_date"
            name="order_date"
            value={formik?.values?.order_date}
            onChange={formik.handleChange}
            sx={{ width: 356 }}
            size="small"
            error={formik?.errors?.order_date}
            helperText={formik?.errors?.order_date}
          />
        </div>
        <div className={css.formRow}>
          <label htmlFor="expected_shipment_date">Expected Shipment Date</label>
          <TextField
            type={"date"}
            id="expected_shipment_date"
            name="expected_shipment_date"
            value={formik?.values?.expected_shipment_date}
            onChange={formik.handleChange}
            sx={{ width: 356 }}
            size="small"
            error={formik?.errors?.expected_shipment_date}
            helperText={formik?.errors?.expected_shipment_date}
            InputProps={{
              inputProps: {
                min: moment(new Date()).format("YYYY-MM-DD"),
              },
            }}
          />
        </div>
        <div className={css.formRow}>
          <label htmlFor="payment_terms">Payment Terms</label>
          <Select
            id="payment_terms"
            name="payment_terms"
            value={formik?.values?.payment_terms}
            onChange={formik.handleChange}
            sx={{ width: 356 }}
            size="small"
            error={formik?.errors?.payment_terms}
            helperText={formik?.errors?.payment_terms}
          >
            {paymentTermOptions?.map((option, i) => {
              return (
                <MenuItem key={`payopt${i}`} value={option?.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <ItemTable defaultItems={allItems} {...{ itemInitValue, formik, itemInitValue,allMajorItems, setAllMajorItems, getTotal }} />
      </div>
      <div className={css.footerActionButtons}>
        <button
          className={`${css.simpleButton} btnOutline btn`}
          type="button"
          onClick={() => {
            formik.setFieldValue("status", "draft");
            formik.handleSubmit();
          }}
        >
          Save as Draft
        </button>
        <button
          type="button"
          className={`${css.primaryButton} btnFill btn`}
          onClick={() => {
            formik.setFieldValue("status", "invoiced");
            formik.handleSubmit();
          }}
        >
          Save and Send
        </button>
        <button
          type="button"
          className={`${css.simpleButton} btnOutline btn`}
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SalesOrderNew;
