import * as Yup from "yup";

const CustomerSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter customer name"),
  email: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
  customer_type: Yup.string()
    .required("Please selecor customer type"),
});

export default CustomerSchema;
