import React, { useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { Autocomplete, MenuItem, Select, TextField } from '@mui/material';
import ItemTableRecieve from './ItemTableRecieve';
import css from './PurchaseOrder.module.css';
import PurchaseOrderSchema from "./PurchaseOrderSchema";
import { createPurchaseReceivedApi, fetchVendorsApi, purchaseEditOrders, purchaseReceived } from '../../ApiHandler/PurchaseOrder.duck';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import "./ItemTableRecieve.css";

const AddNewPurchaseRecieve = () => {
  const { Id } = useParams();
  const [editData, setEditData] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [allMajorItems, setAllMajorItems] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState({});
  const [vendorQuery, setVendorQuery] = useState('');
  const [addVendor, setAddVendor] = useState(false);

  const navigate = useNavigate();

  const handleBeforeUnload = (event) => {
    event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  const paymentTermOptions = [
    { label: 'Upon Receipt', value: 'upon_receipt' },
    { label: 'Net 15', value: 'net15' },
    { label: 'Net 30', value: 'net30' },
  ];

  const fetchPurchaseReceive = async () => {
    const response = await purchaseReceived(Id);
    const purchaseOrder = response?.data?.purchase_received;
    if (purchaseOrder) {
      setAllItems([...purchaseOrder.items]);
      setEditData({ ...purchaseOrder });
    }
  };

  useEffect(() => {
    if (Id) {
      fetchPurchaseReceive();
    }
  }, []);

  const createPurchaseReceived = async () => {
    const formdata = JSON.parse(JSON.stringify(formik.values));
    const params = { purchase_receive: formdata };
    createPurchaseReceivedApi(params).then((response) => {
      if (response.success) {
        toast('Saved successfully');
        resetForm();
        navigate(`/purchaseRecieveShow/${Id}`);
      } else {
        toast(response.error);
      }
    });
  };

  const formik = useFormik({
    initialValues: editData?.id ? editData : {
      items: allItems,
      received_date: editData?.received_date
    },
    onSubmit: createPurchaseReceived,
    enableReinitialize: true,
  });
  
  const getTotal = useMemo(() => {
    
  }, [formik.values.items, allMajorItems]);


  const handleCancel = () => {
    if (Id) {
      window.location.replace(`/purchase/${Id}`);
    } else {
      window.location.replace(`/`);
    }
  };

  const resetForm = () => {
    formik.resetForm();
    setAllVendors([]);
    setVendorQuery('');
  };


  return (
    <div className={`${css.commonBox } addNewPurchaseReceive`}>
      <form onSubmit={formik.handleSubmit}>
        <h2 className={css.pageHeader}>{ 'New'} Purchase Receive</h2>
        <div className={css.formWrapper}>
          <div className={`${css.formRow} formRow`}>
            Purchase Order
            <b>{editData?.formatted_order_number}</b>
          </div>
          <div className={`${css.formRow} formRow`}>
            Vendor
            <b>{editData?.vendor_name}</b>
          </div>
          <div className={`${css.formRow} formRow`}>
            Date received
            <TextField
              type={"date"}
              id="received_date"
              name="received_date"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.received_date}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.received_date}
              helperText={formik?.errors?.received_date}
            />
          </div>
          <ItemTableRecieve
            defaultItems={allItems}
            {...{
              formik,
              editData,
            }}
          />
        </div>
        <div className={css.footerActionButtons}>
          <button
            className={`${css.primaryButton} btnFill btn`}
            type="button"
            onClick={() => {
              formik.setFieldValue('status', 'issued');
              formik.handleSubmit();
            }}
          >
            Save
          </button>
          <button
            type="button"
            className={`${css.simpleButton} btnOutline btn`}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewPurchaseRecieve;