import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Detail from "../../components/Detail/Detail";
import { deleteCustomer, toggleStatus } from "../../ApiHandler/Customer.duck";
import { Button } from "@mui/material";

const CustomerShow = () => {
  const { Id } = useParams();
  const [customerData, setCustomerData] = useState({});
  const [currentStatus, setCurrentStatus] = useState("active");
  const [showDelete, setShowDelete] = useState(false);
  const navigate = useNavigate();
  const customerShow = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/customer/${Id}`, {
      method: "GET",
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.customer) {
          setCustomerData(data?.customer);
          setCurrentStatus(data?.customer?.status);
        }
      });
  };

  useEffect(() => {
    customerShow();
  }, []);

  const headings = [
    "DATE",
    "PURCHASE ORDER#",
    "VENDOR NAME",
    "QUANTITY RECEIVED",
    "STATUS",
    "PRICE",
    "TOTAL",
  ];

  const getformattedPurchaseData = () => {
    return [
      { key: "Type", value: customerData?.customer_type || "Not listed" },
      { key: "Email", value: customerData?.email || "Not listed" },
      { key: "Name", value: customerData?.name || "Not listed" },
      {
        key: "Phone",
        value: customerData?.phone || "Not listed",
      },
      {
        key: "Payment terms",
        value: customerData?.payment_terms || "Not listed",
      },
    ];
  };

  const handleDelete = async () => {
    let response = {};
    if (response?.data?.success) {
      toast("Deleted Successfully");
      navigate("/");
    } else {
      toast(response?.data?.error);
    }
  };

  const handleToggleStatus = async () => {
    const status = currentStatus === "active" ? "inactive" : "active";
    setCurrentStatus(status);
    const response = await toggleStatus(Id, status);
    toast("Updated successfully");
  };

  const actionButtons = [
    <Button
      type="button"
      variant="contained"
      onClick={() => {
          navigate(`/customers-new/${Id}`);
      }}
    >
      Edit
    </Button>,
    <Button
      type="button"
      variant="contained"
      onClick={() => handleToggleStatus()}
    >
      Mark as {currentStatus === "active" ? "Inactive" : "Active"}
    </Button>,

    <Button
      type="button"
      variant="contained"
      onClick={() => setShowDelete(true)}
    >
      Delete
    </Button>
  ]
  return (
    <>
      <Detail
        showData={getformattedPurchaseData()}
        salesData={customerData}
        headings={headings}
        backUrl="/customers"
        handleDelete={handleDelete}
        editUrl={`/customers-new/${Id}`}
        currentStatus={currentStatus}
        setCurrentStatus={setCurrentStatus}
        deleteCustomer={deleteCustomer}
        toggleStatus={toggleStatus}
        actionButtons={actionButtons}
        showDelete={showDelete}
        setShowDelete={setShowDelete}
      />
    </>
  );
};

export default CustomerShow;
