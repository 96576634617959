import "./App.css";
import AppRoutes from "./Routes/AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppLayout from "./components/Layout/AppLayout";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCurrentUserApi } from "./ApiHandler/Auth";

function App() {
  const [currentRole, setCurrentRole] = useState();
  const [authLoaded, setAuthLoaded] = useState(false);
  const getCurrentUser = async ()=>{
    const response = await getCurrentUserApi();
    if(response?.data?.email){
      setCurrentRole(response.data?.role);
      localStorage.setItem("currentUser", JSON.stringify({name: response.data.username, email: response.data.email, userid: response.data?.id}) )
    }
    setAuthLoaded(true)
  }
  useEffect(()=>{
    getCurrentUser();
  },[])

  return (
    <>
      <AppLayout {...{currentRole, setCurrentRole, authLoaded}} >
        <AppRoutes {...{currentRole}}/>
        <ToastContainer />
      </AppLayout>
    </>
  );
}

export default App;
