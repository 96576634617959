import React, { useEffect, useState } from "react";
import CommonTable from "../../components/CommonTable";
import css from "../PurchaseOrder/PurchaseOrder.module.css";
import ButtonGroup from "../../components/Layout/ButtonGroup";
import Button from "../../components/Layout/Button";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { fetchCategoriesApi } from "../../ApiHandler/Categories.duck.js";
import { sortArray } from "../../components/Helper";

const Category = () => {
  const allStates = ["all", "active", "inactive"];
  const [apiData, setApiData] = useState();
  const [currentState, setCurrentState] = useState(allStates[0]);
  const [allCategories, setCategories] = useState([]);
  const [sortAsc, setSortAsc] = useState(false);
  const navigate = useNavigate();
  const { Id } = useParams();
  const headings = ["Sr No.", "Category Name"];
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const fetchCategories = async () => {
    const response = await fetchCategoriesApi(currentState, page + 1);
    console.log("response",response)
    setApiData(response?.categories || []);
    let tableData = response?.categories?.map((o, i) => {
      return [o.id, i+1,  o.name];
    });

    setCategories([]);
    setCategories(tableData);
    setTotal(response?.data?.total);
  };

  const setSortedData = (key) => {
    let tableData = sortArray(apiData, key, `${sortAsc ? "asc" : ""}`);
    tableData = tableData?.map((o, i) => {
      return [o.id,i+1, o.name];
    });
    setCategories(tableData);
    setSortAsc(!sortAsc);
  };

  useEffect(() => {
    fetchCategories();
  }, [currentState, Id]);

  if (Id) {
    return <Outlet />;
  }

  return (
    <div className={css.commonBox}>
      <div
        className={`${css.flex} justify_between align_center pageHeaderWrapper`}
      >
        <h2 className={`${css.pageHeader} ${css.m0}`}>Categories</h2>
        <Button
          type="button"
          className={css.commonButton || "btnFill btn"}
          onClick={() => navigate("/categoriesNew")}
        >
          New Category
        </Button>
      </div>
      <div class="buttonGroups"></div>
      <CommonTable
        {...{ headings, page, total, onPageChange, setSortedData }}
        tableData={allCategories}
        inactiveWholeRow={[]}
        sortKey={[
          { index: 1, key: "name" },
        ]}
        noPaginate={true}
      />
    </div>
  );
};

export default Category;
