import React, { useEffect, useState } from "react";
import Detail from "../../components/Detail/Detail";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteSalesOrder,
  markConfirmAPi,
} from "../../ApiHandler/SalesOrder.duck";
import { toast } from "react-toastify";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfComponent from "../../components/Pdf/PdfComponent";
import { Button } from "@mui/material";
import PreviewPdf from "../../components/Pdf/PreviewPdf";
import css from "./salesOrderShow.css";

const SalesOrderShow = (props) => {
  const { Id } = useParams();
  const [salesData, setSalesData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [pdfTableData, setPdfTableData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const editUrl=`/sales-orders-new/${salesData?.id}`;
  const pdfFileName=`sales-order-${salesData?.order_number}.pdf`;
  const pdfHeading="Sales order";
  const orderNumber=`Sales order# SO-${salesData?.order_number}`;
  const previewPdfToggle = () => {
    setTogglePdf(!togglePdf);
    setPreviewPdf(!previewPdf);
  };
  const [showDelete, setShowDelete] = useState(false);
  const [togglePdf, setTogglePdf] = useState(false);
  const navigate = useNavigate();
  const salesOrderShow = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/sales_order/${Id}`, {
      method: "GET",
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.sales_order) {
          data.sales_order.name = data?.sales_order?.formatted_order_number
          setSalesData(data?.sales_order);
          const tbdata =
            data?.sales_order?.ordered_products?.map((p) => [
              p.quantity?.toFixed(2),
              p.title,
              `$${p.price?.toFixed(2)}`,
              `$${p.amount?.toFixed(2)}`,
            ]) || [];
          setTableData([...tbdata]);

          const pdftbdata =
            data?.sales_order?.ordered_products?.map((p, i) => [
              `${i + 1}`,
              p.title,
              p.quantity,
              `$${p.price?.toFixed(2)}`,
              `$${p.amount?.toFixed(2)}`,
            ]) || [];
          setPdfTableData([...pdftbdata]);
        }
      });
  };
  useEffect(() => {
    salesOrderShow();
  }, []);

  const markConfirm = async () => {
    const response = await markConfirmAPi(Id);
    if (response?.data?.success) {
      salesOrderShow();
      toast("Confirmed Successfully");
    }
  };

  const getTotal = () => {
    let totalAmount = 0;
    let totalQty = 0;
    salesData?.ordered_products?.map((o) => {
      totalAmount += o.amount;
      totalQty += o.quantity;
    });
    return { totalAmount: totalAmount, totalQty: totalQty };
  };

  const handleDelete = async () => {
    const response = await deleteSalesOrder(Id);
    if (response?.data?.success) {
      toast("Deleted Successfully");
      navigate("/sales-orders");
    } else {
      toast(response?.data?.error);
    }
  };

  const actionButtons = [
    <Button
      type="button"
      variant="contained"
      onClick={() => {
        if (editUrl) {
          navigate(editUrl);
        }
      }}
    >
      Edit
    </Button>,
    <Button
      type="button"
      variant="contained"
      onClick={() => console.log("Edit clicked")}
    >
      Email
    </Button>,
    <>
    <div className={css.print_pdf_button_wrapper}>
      <Button
        type="button"
        variant="contained"
        onClick={() => setTogglePdf(!togglePdf)}
      >
        Pdf/Print
      </Button>
      {togglePdf && (
        <ul className={css.print_pdf_drop_down}>
          <li
            className={`${css.print_pdf_list} print_pdf_button`}
            onClick={() =>
              setTimeout(() => setTogglePdf(!togglePdf), 500)
            }
          >
            <PDFDownloadLink
              document={
                <PdfComponent
                  invoice={salesData}
                  pdfTableData={pdfTableData}
                  pdfHeading={pdfHeading}
                  orderNumber={orderNumber}
                  totalAmount={getTotal()?.totalAmount}
                />
              }
              fileName={pdfFileName || "victor.pdf"}
              className="buttonDowmload"
            >
              {({ loading }) =>
                loading ? (
                  "Pdf"
                ) : (
                  <>
                    <div className="pdf-export">Pdf</div>
                  </>
                )
              }
            </PDFDownloadLink>
          </li>
          <li className="">
            <a onClick={previewPdfToggle}>Preview</a>
          </li>
        </ul>
      )}
    </div>
    {previewPdf && (
      <PreviewPdf
        {...{
          previewPdf,
          setPreviewPdf,
          pdfTableData,
          pdfHeading,
          orderNumber,
        }}
        salesData={salesData}
        totalAmount={getTotal()?.totalAmount}
      />
    )}
    </>
    ,

    <>
      {
        !salesData?.shipped && (
          <Button
            type="button"
            variant="contained"
            onClick={() => {
              if(!salesData?.date_delivered){
                navigate(`/salesDelivery/${Id}`)
              }else{
                navigate(`/salesDeliveryShow/${Id}`)
              }
            }}
          >
            Deliver
          </Button>
        )
      }
    </>
    ,
    <>
    {(!salesData?.confirmed && !salesData?.shipped) && (
      <Button type="button" variant="contained" onClick={markConfirm}>
        Mark as Confirmed
      </Button>
    )}
    </>,
    <Button
      type="button"
      variant="contained"
      onClick={() => setShowDelete(true)}
    >
      Delete
    </Button>
  ]

  const headings = ["QUANTITY", "ITEM DETAILS", "PRICE", "AMOUNT"];
  const getformattedSalesData = () => {
    return [
      { key: "Customer", value: salesData?.customer_name },
      { key: "Status", value: salesData?.status },
      { key: "Date", value: salesData?.order_date },
      { key: "Shipped", value: salesData?.shipped ? "Shipped" : "Not shipped" },
      { key: "Delivery Date", value: salesData?.delivery_date },
      {
        key: "Invoiced",
        value: salesData?.billed ? "Invoiced" : "Not invoiced",
      },
      { key: "Payment Terms", value: salesData?.payment_terms },
    ];
  };
  return (
    <>
      <Detail
        salesData={salesData}
        showData={getformattedSalesData()}
        headings={headings}
        tableData={tableData}
        getTotal={getTotal}
        {...{ pdfTableData, setPdfTableData }}
        backUrl="/sales-orders"
        markConfirm={markConfirm}
        handleDelete={handleDelete}
        editUrl={`/sales-orders-new/${Id}`}
        pdfFileName={`sales-order-${salesData?.order_number}.pdf`}
        {...{showDelete, setShowDelete}}
        actionButtons={actionButtons}
      />
    </>
  );
};

export default SalesOrderShow;
