import React from "react";
import MuiDrawer from "@mui/material/Drawer";
import { List, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SLIDER_LISTING1, SLIDER_LISTING2 } from "./SidebarConstant";
import css from "./Sidebar.module.css";
import SidebarLink from "./SidebarLink";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  zIndex: "0",
  marginTop: "64px",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  zIndex: "0",
  marginTop: "64px",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 24px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideBar = ({ children, open, setOpen, location, currentRole }) => {
  return (
    <Box
      sx={{ display: "flex" }}
      className={`${css.sideMain} ${open ? "openSideBar" : "closeSidebar"}`}
    >
      {/* <Drawer variant="permanent" open={true}> */}
      <List className={`${css.sideBarList} sideBarList`}>
        {currentRole === "admin"
          ? SLIDER_LISTING1.map((item) => {
              return (
                <SidebarLink
                  key={item.id}
                  label={item.label}
                  link={item.link}
                  location={location}
                  setOpen={setOpen}
                  icon={item.icon}
                />
              );
            })
          : SLIDER_LISTING2.map((item) => {
              return (
                <SidebarLink
                  key={item.id}
                  label={item.label}
                  link={item.link}
                  location={location}
                  setOpen={setOpen}
                  icon={item.icon}
                />
              );
            })}
      </List>
      {/* </Drawer> */}
      <div sx={{ p: 3 }} className={`${css.mainContent} mainContent`}>
        {children}
      </div>
    </Box>
  );
};

export default SideBar;
