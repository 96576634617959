import React, { useEffect, useState } from "react";
import CommonTable from "../../components/CommonTable";
import css from "../PurchaseOrder/PurchaseOrder.module.css";
import ButtonGroup from "../../components/Layout/ButtonGroup";
import Button from "../../components/Layout/Button";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { fetchTariffsApi } from "../../ApiHandler/Tariffs.duck";
import { sortArray } from "../../components/Helper";

const Tariff = () => {
  const allStates = ["all"];
  const [currentState, setCurrentState] = useState(allStates[0]);
  const [apiData, setApiData] = useState();
  const [allTariffs, setTariffs] = useState([]);
  const [sortAsc, setSortAsc] = useState(false);
  const navigate = useNavigate();
  const { Id } = useParams();
  const headings = [
    "Code",
    "Description of Goods",
    "ICD Rate",
    "UNIT",
    "ICT RATE",
  ];
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const setSortedData = (key) => {
    let tableData = sortArray(apiData, key, `${sortAsc ? "asc" : ""}`);
    tableData = tableData?.map((o) => {
      return [o.id, o.code, o.description, o.icd_rate, o.unit, o.ict_rate];
    });
    setTariffs(tableData);
    setSortAsc(!sortAsc);
  };

  const fetchTariffs = async () => {
    const response = await fetchTariffsApi(currentState, page + 1);
    setApiData(response?.data?.tariffs || []);
    let tableData = response?.data?.tariffs?.map((o) => {
      return [o.id, o.code, o.description, o.icd_rate, o.unit, o.ict_rate];
    });

    setTariffs([]);
    setTariffs(tableData);
    setTotal(response?.data?.total);
  };

  useEffect(() => {
    fetchTariffs();
  }, [currentState, Id]);

  if (Id) {
    return <Outlet />;
  }

  return (
    <div className={css.commonBox}>
      <div
        className={`${css.flex} justify_between align_center pageHeaderWrapper`}
      >
        <h2 className={`${css.pageHeader} ${css.m0}`}>Tariff</h2>
        <Button
          type="button"
          className={css.commonButton || "btnFill btn"}
          onClick={() => navigate("/tariffs-new")}
        >
          New Tariff
        </Button>
      </div>
      <ButtonGroup>
        {allStates?.map((state, ind) => {
          return (
            <Button
              type="button"
              key={ind}
              selected={currentState == state}
              onClick={() => setCurrentState(state)}
            >
              {state}
            </Button>
          );
        })}
      </ButtonGroup>
      <CommonTable
        {...{ headings, page, total, onPageChange, setSortedData }}
        tableData={allTariffs}
        sortKey={[{ index: 0, key: "code" }]}
      />
    </div>
  );
};

export default Tariff;
