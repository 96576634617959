import * as Yup from "yup";

const VendorSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter vendor name"),
  email: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
  vendor_type: Yup.string()
    .required("Please selecor vendor type"),
});

export default VendorSchema;
