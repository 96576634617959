import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteStockAdjustment,
  toggleStatus,
} from "../../ApiHandler/StockAdjustment.duck";
import Detail from "../../components/Detail/Detail";
import { Button } from "@mui/material";

const StockAdjustmentShow = () => {
  const { Id } = useParams();
  const [stockAdjustmentData, setStockAdjustmentData] = useState({});
  const [currentStatus, setCurrentStatus] = useState();
  const [transactionsOf, setTransactionOf] = useState({
    of: "purchase_orders",
    status: "all",
  });
  const [showDelete, setShowDelete] = useState(false);
  const navigate = useNavigate();

  const stockAdjustmentShow = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/stock_adjustment/${Id}`, {
      method: "GET",
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.stock_adjustment) {
          setStockAdjustmentData(data?.stock_adjustment);
          setCurrentStatus(data?.stock_adjustment?.status);
        }
      });
  };

  useEffect(() => {
    stockAdjustmentShow();
  }, []);

  const getFormattedStockAdjustmentData = () => {
    return [
      {
        key: "Date",
        value: stockAdjustmentData?.adjustment_date || "Not listed",
      },
      { key: "Status", value: currentStatus || "Not listed" },
      {
        key: "Quantity Adjusted",
        value: stockAdjustmentData?.qty_adjusted || "Not listed",
      },
      { key: "Reason", value: stockAdjustmentData?.reason },
      { key: "Description", value: stockAdjustmentData?.description },
    ];
  };

  const handleDelete = async () => {
    const response = await deleteStockAdjustment(Id);
    if (response?.data?.success) {
      toast("Deleted Successfully");
      navigate("/adjustments");
    } else {
      toast(response?.data?.error);
    }
  };

  const handleToggleStatus = async () => {
    const status = currentStatus?.toLowerCase() === "adjusted" ? "draft" : "adjusted";
    setCurrentStatus(status);
    const response = await toggleStatus(Id, status);
    toast("Updated successfully");
  };

  const actionButtons = [
    <Button
      type="button"
      variant="contained"
      onClick={() => {
          navigate(`/adjustments-new/${Id}`);
      }}
    >
      Edit
    </Button>,
    <Button
      key={currentStatus}
      type="button"
      variant="contained"
      onClick={() => handleToggleStatus()}
    >
      Mark as {currentStatus?.toLowerCase() === "adjusted" ? "draft" : "Adjusted"}
    </Button>,
    <Button
      type="button"
      variant="contained"
      onClick={() => setShowDelete(true)}
    >
      Delete
    </Button>
  ]
  return (
    <>
      <Detail
        showDelete={showDelete}
        setShowDelete={setShowDelete}
        showData={getFormattedStockAdjustmentData()}
        salesData={stockAdjustmentData}
        headings={[]}
        tableData={[]}
        backUrl="/adjustments"
        setTransactionOf={setTransactionOf}
        transactionsOf={transactionsOf}
        handleDelete={handleDelete}
        editUrl={`/adjustments-new/${Id}`}
        currentStatus={currentStatus}
        setCurrentStatus={setCurrentStatus}
        deleteCustomer={deleteStockAdjustment}
        toggleStatus={toggleStatus}
        actionButtons={actionButtons}
      />
    </>
  );
};

export default StockAdjustmentShow;
