import React from "react";

const Button = (props)=>{
    const {selected, children} = props;
    //Duplicate props to add classname
    const customProps = {}
    Object.keys(props).map((key)=> customProps[key] = props[key]);
    customProps.className += ` commonButton fcapitalize ${selected ? 'buttonSelected' : ''}`;

    return(
        <button {...customProps}>{children}</button>
    )
}

export default Button;