import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Detail from "../../components/Detail/Detail";
import { Button } from "@mui/material";

const TariffShow = () => {
  const { Id } = useParams();
  const [tariffData, setTariffData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const navigate = useNavigate();
  const tariffShow = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/tariff/${Id}`, {
      method: "GET",
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.tariff) {
          data.tariff.name = data?.tariff?.code
          setTariffData(data?.tariff);
        }
      });
  };

  useEffect(() => {
    tariffShow();
  }, []);

  const headings = [];

  const getformattedPurchaseData = () => {
    return [
      { key: "Code", value: tariffData?.code },
      { key: "Description", value: tariffData?.description },
      { key: "ICD Rate", value: tariffData?.icd_rate },
      {
        key: "Unit",
        value: tariffData?.unit,
      },
      { key: "ICT rate", value: tariffData?.ict_rate },
    ];
  };

  const handleDelete = async () => {
    let response = {};
    if (response?.data?.success) {
      toast("Deleted Successfully");
      navigate("/");
    } else {
      toast(response?.data?.error);
    }
  };

  const actionButtons = [
    <Button
      type="button"
      variant="contained"
      onClick={() => {
          navigate(`/tariffs-new/${Id}`);
      }}
    >
      Edit
    </Button>
  ]
  return (
    <>
      <Detail
        showData={getformattedPurchaseData()}
        salesData={tariffData}
        headings={headings}
        tableData={tableData}
        backUrl="/tariffs"
        handleDelete={handleDelete}
        editUrl={`/tariffs-new/${Id}`}
        actionButtons={actionButtons}
      />
    </>
  );
};

export default TariffShow;
