import axios from "axios";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const fetchCustomersApi = async (status)=>{
    return await axios.get(
        `${BACKEND_URL}/customers?status=${status}`
    );
}

const createCustomerApi = async (params) => {
    const response = await fetch(`${BACKEND_URL}/customers`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
    });
    return await response.json();
};

const deleteCustomer = async (Id) => {
    try{
      return await axios.delete(`${BACKEND_URL}/customers/${Id}`);
    }catch(error){
      return {error: error}
    }
};

const toggleStatus = async (id, status) => {
    return await axios.get(
      `${BACKEND_URL}/customer_toggle_status/${id}/${status}`
    );
};

const fetchCustomerEdit = async (Id)=>{
    return await axios.get(
        `${BACKEND_URL}/customer_edit/${Id}`
    );
}


export {fetchCustomersApi, createCustomerApi, deleteCustomer, toggleStatus, fetchCustomerEdit}