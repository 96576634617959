import React, { useEffect, useState } from "react";
import css from "../PurchaseOrder/PurchaseOrder.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import TariffSchema from "./TariffSchema";
import { toast } from "react-toastify";
import { fetchTariffEdit,createTariffApi } from "../../ApiHandler/Tariffs.duck";

const TariffNew = () => {
  const { Id } = useParams();
  const navigate = useNavigate()
  const [ editData, setEditData ] = useState({});

  const initValues = {
    code: "",
    description: "",
    ict_rate: "",
    unit: "0",
    icd_rate: "",
  };

  const createTariff = ()=>{
    let formData =  JSON.parse(JSON.stringify(formik.values));
    createTariffApi({tariff: formData}).then((response)=> {
      if(response.success){
        toast("Tariff created successfully");
        navigate('/tariffs')
      }else{
        console.log("response.error",response.error);
        toast(response.error)
      }
    })
  }

  const getEdit = async ()=>{
    if(Id){
      const response = await fetchTariffEdit(Id);
      setEditData({});
      setEditData(response?.data?.edit_data);
    }
  }

  useEffect(() => {
    getEdit();
  }, []);

  const formik = useFormik({
    initialValues: editData?.id ? editData : initValues,
    onSubmit: createTariff,
    validationSchema: TariffSchema,
    enableReinitialize: true,
  });

  const handleCancel = ()=>{
    navigate(`/tariffs`)
  }

  return (
    <div className={css.commonBox}>
      <form onSubmit={formik.handleSubmit}>
        <h2 className={css.pageHeader}>{Id ? "Edit" : "New"} Tariff</h2>
        <div className={css.formWrapper}>
          <div className={css.formRow}>
            <label htmlFor="code">Code</label>
            <TextField
              type={"text"}
              id="code"
              name="code"
              sx={{ width: 356, height: 38 }}
              value={formik.values?.code}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.code}
              helperText={formik?.errors?.code}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="description">Description of goods</label>
            <TextField
              type={"text"}
              id="description"
              name="description"
              multiline={5}
              sx={{ width: 356, height: 38 }}
              value={formik.values?.description}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.description}
              helperText={formik?.errors?.description}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="name">ICD Rate</label>
            <TextField
              type={"number"}
              id="icd_rate"
              name="icd_rate"
              multiline={5}
              sx={{ width: 356, height: 38 }}
              value={formik.values?.icd_rate}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.icd_rate}
              helperText={formik?.errors?.icd_rate}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="name">ICT Rate</label>
            <TextField
              type={"number"}
              id="ict_rate"
              name="ict_rate"
              multiline={5}
              sx={{ width: 356, height: 38 }}
              value={formik.values?.ict_rate}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.ict_rate}
              helperText={formik?.errors?.ict_rate}
            />
          </div>
          <div className={css.formRow}>
            <label htmlFor="name">Unit</label>
            <TextField
              type={"number"}
              id="unit"
              name="unit"
              multiline={5}
              sx={{ width: 356, height: 38 }}
              value={formik.values?.unit}
              onChange={formik.handleChange}
              size="small"
              error={formik?.errors?.unit}
              helperText={formik?.errors?.unit}
            />
          </div>
          
        </div>
        <div className={css.footerActionButtons}>
          <button
                className={`${css.primaryButton} btnFill btn`}
                type="submit"
          >
            Save
          </button>
          <button
            type="button"
           className={`${css.simpleButton} btnOutline btn`}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default TariffNew;
