import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Detail from "../../components/Detail/Detail";
import { purchaseReceived } from "../../ApiHandler/PurchaseOrder.duck";

const PurchaseReceiveShow = (props) => {
  const { Id } = useParams();
  const [purchaseData, setPurchasaeData] = useState({});
  const [tableData, setTableData] = useState([]);

  const purchaseOrderShow = async () => {
    const response = await purchaseReceived(Id);
    const purchaseOrder = response?.data?.purchase_received;
    if (purchaseOrder) {
      purchaseOrder.name = "Purchase Receive";
      setPurchasaeData({ ...purchaseOrder });
      const tbdata =
        purchaseOrder?.items?.map((p) => [p.name, p.received_qty]) || [];
      setTableData([...tbdata]);
    }
  };
  useEffect(() => {
    purchaseOrderShow();
  }, []);

  const headings = ["ITEM DETAILS", "QTY RECEIVED"];
  const getformattedPurchaseData = () => {
    return [
      { key: "Purchase Order", value: purchaseData?.formatted_order_number },
      { key: "Vendor", value: purchaseData?.vendor_name },
      {
        key: "Date Received",
        value: purchaseData?.received_date,
      },
    ];
  };

  const getTotal = () => {
    return {
      totalQty:
        purchaseData?.items?.reduce(
          (total, obj) => +obj.received_qty + +total,
          0
        ) || 0,
    };
  };
  return (
    <>
      <Detail
        name={"Purchase Receive"}
        showData={getformattedPurchaseData()}
        salesData={purchaseData}
        headings={headings}
        tableData={tableData}
        actionButtons={[]}
        backUrl={`/purchase/${Id}`}
        getTotal={getTotal}
        showTotalItems={true}
      />
    </>
  );
};

export default PurchaseReceiveShow;
