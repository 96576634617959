import * as Yup from "yup";

const CustomerSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
  last_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
  email: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter email"),
  password: Yup.string()
    .min(6, "Too Short!")
    .max(50, "Too Long!")
});

export default CustomerSchema;
